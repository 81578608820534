<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="
                    card card-custom
                    gutter-b
                    bg-transparent
                    shadow-none
                    border-0
                  ">
                                    <div class="
                      card-header
                      align-items-center
                      border-bottom-dark
                      px-0
                    ">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Top Offer banner
                                            </h3>
                                        </div>
                                        <div class="icons d-flex">
                                            <button class="btn ml-2 p-0 kt_notes_panel_toggle" data-toggle="tooltip"
                                                title="" data-placement="right" data-original-title="Check out more demos"
                                                v-if="$parent.permissions.includes('top-offer-manage')
                                                    ">
                                                <span class="
                            bg-secondary
                            h-30px
                            font-size-h5
                            w-30px
                            d-flex
                            align-items-center
                            justify-content-center
                            rounded-circle
                            shadow-sm
                          " v-on:click="display_form = !display_form">
                                                    <svg width="25px" height="25px" viewBox="0 0 16 16"
                                                        class="bi bi-plus white" fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z">
                                                        </path>
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div>
                                            <div class="table-responsive" id="printableTable">
                                                <div id="productsliderTable_wrapper" class="dataTables_wrapper no-footer">
                                                    <div class="dataTables_length" id="productsliderTable_length">
                                                        <label>Show
                                                            <select name="productsliderTable_length" class=""
                                                                v-model="limit" v-on:change="fetchOffers()">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                                <option value="200">200</option>
                                                                <option value="500">500</option>
                                                                <option value="1000">1000</option>
                                                            </select>
                                                            entries</label>
                                                    </div>

                                                    <div id="productsliderTable_filter" class="dataTables_filter">
                                                        <label>
                                                            Search:<input type="text" class="" placeholder=""
                                                                v-model="searchParameter" @keyup="fetchOffers()" />
                                                        </label>
                                                        <button v-if="this.searchParameter != ''" @click="clearSearch">
                                                            clear
                                                        </button>
                                                    </div>
                                                    <table id="productsliderTable" class="display dataTable no-footer"
                                                        role="grid">
                                                        <thead class="text-body">
                                                            <tr role="row">
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-sort="ascending"
                                                                    aria-label="ID: activate to sort column descending"
                                                                    style="width: 31.25px" @click="sorting('id')" :class="(this.$data.sortType == 'asc' ||
                                                                        this.$data.sortType == 'ASC') &&
                                                                        this.$data.sortBy == 'id'
                                                                        ? 'sorting_asc'
                                                                        : (this.$data.sortType == 'desc' ||
                                                                            this.$data.sortType == 'DESC') &&
                                                                            this.$data.sortBy == 'id'
                                                                            ? 'sorting_desc'
                                                                            : 'sorting'
                                                                        ">
                                                                    ID
                                                                </th>
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-label="slider: activate to sort column ascending"
                                                                    style="width: 95.5288px" @click="sorting('title')"
                                                                    :class="(this.$data.sortType == 'asc' ||
                                                                        this.$data.sortType == 'ASC') &&
                                                                        this.$data.sortBy == 'title'
                                                                        ? 'sorting_asc'
                                                                        : (this.$data.sortType == 'desc' ||
                                                                            this.$data.sortType == 'DESC') &&
                                                                            this.$data.sortBy == 'title'
                                                                            ? 'sorting_desc'
                                                                            : 'sorting'
                                                                        ">
                                                                    Text
                                                                </th>

                                                                <th v-if="$parent.permissions.includes(
                                                                    'top-offer-manage'
                                                                )
                                                                    " class="no-sort sorting_disabled" rowspan="1"
                                                                    colspan="1" aria-label="Action"
                                                                    style="width: 53.1891px">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="kt-table-tbody text-dark">
                                                            <tr class="kt-table-row kt-table-row-level-0 odd" role="row"
                                                                v-for="offer in offers" v-bind:key="offer.id">
                                                                <td class="sorting_1">
                                                                    {{ offer.id }}
                                                                </td>
                                                                <td>
                                                                    {{ offer.top_offer_text }}
                                                                </td>
                                                                <td v-if="$parent.permissions.includes(
                                                                    'top-offer-manage'
                                                                )
                                                                    ">
                                                                    <a href="javascript:void(0)" class="click-edit"
                                                                        id="click-edit1" data-toggle="tooltip" title=""
                                                                        data-placement="right"
                                                                        data-original-title="Check out more demos"
                                                                        @click="editOffer(offer)"><i
                                                                            class="fa fa-edit"></i></a>
                                                                    <a class="" href="#"
                                                                        @click="deleteOffer(offer.id)"><i
                                                                            class="fa fa-trash"></i></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <ul class="pagination pagination-sm m-0 float-right">
                                                        <li v-bind:class="[
                                                            { disabled: !pagination.prev_page_url },
                                                        ]">
                                                            <button class="page-link" href="#"
                                                                @click="fetchOffers(pagination.prev_page_url)">
                                                                Previous
                                                            </button>
                                                        </li>

                                                        <li class="disabled">
                                                            <button class="page-link text-dark" href="#">
                                                                Page {{ pagination.current_page }} of
                                                                {{ pagination.last_page }}
                                                            </button>
                                                        </li>

                                                        <li v-bind:class="[
                                                            { disabled: !pagination.next_page_url },
                                                        ]" class="page-item">
                                                            <button class="page-link" href="#"
                                                                @click="fetchOffers(pagination.next_page_url)">
                                                                Next
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel" v-if="display_form"
            :class="display_form ? 'offcanvas-on' : ''">
            <div class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-3
        ">
                <h4 class="font-size-h4 font-weight-bold m-0">Add offer</h4>
                <a href="#" class="
                    btn btn-sm btn-icon btn-light btn-hover-primary
                    kt_notes_panel_close
                " v-on:click="clearForm()">
                    <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z">
                        </path>
                    </svg>
                </a>
            </div>
            <form id="myform">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="text-dark">Offer text</label>
                            <fieldset class="form-group mb-3 d-flex">
                                <VueEditor v-model="top_offer_text" />
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('top_offer_text')"
                                v-text="errors.get('top_offer_text')"></small>
                        </div>

                    </div>
                </div>
                <button type="button" @click="addUpdateOffer()" class="btn btn-primary" style="margin-top: 85px">
                    Submit
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import ErrorHandling from "../../ErrorHandling";
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueEditor
    },
    data() {
        return {
            display_form: 0,
            offers: {
                id: "",
                top_offer_text: "",
            },
            top_offer_text: "",
            showModal: false,
            searchParameter: "",
            sortBy: "id",
            sortType: "ASC",
            limit: 10,
            error_message: "",
            edit: false,
            actions: false,
            pagination: {},
            request_method: "",
            sliders: [],
            pages: [],
            token: [],
            gallary_path: "",
            errors: new ErrorHandling(),
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
        };
    },

    methods: {
        fetchOffers(page_url) {
            this.$parent.loading = true;
            let vm = this;
            page_url = page_url || "/api/admin/top-offer";
            var arr = page_url.split("?");

            if (arr.length > 1) {
                page_url += "&limit=" + this.limit;
            } else {
                page_url += "?limit=" + this.limit;
            }
            if (this.searchParameter != null) {
                page_url += "&searchParameter=" + this.searchParameter;
            }

            var responseData = {};

            axios
                .get(page_url, this.token)
                .then((res) => {
                    this.offers = res.data.data;
                    vm.makePagination(res.data.meta, res.data.links);
                })
                .finally(() => (this.$parent.loading = false));
        },

        makePagination(meta, links) {
            let pagination = {
                current_page: meta.current_page,
                last_page: meta.last_page,
                next_page_url: links.next,
                prev_page_url: links.prev,
            };

            this.pagination = pagination;
        },
        deleteOffer(id) {
            if (confirm("Are You Sure?")) {
                this.$parent.loading = true;
                axios
                    .delete(`/api/admin/top-offer/${id}`, this.token)
                    .then((res) => {
                        if (res.data.status) {
                            this.$toaster.success("Settings has been updated successfully");
                            this.fetchOffers();
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => (this.$parent.loading = false));
            }
        },
        addUpdateOffer() {
            this.$parent.loading = true;
            var url = "/api/admin/top-offer";

            if (this.edit === false) {
                // Add
                this.request_method = "post";
            } else {
                // Update
                var url = "/api/admin/top-offer/" + this.offers.id;
                this.request_method = "put";
            }

            console.log(this.offers);
            axios[this.request_method](url, { 'top_offer_text': this.top_offer_text }, this.token)
                .then((res) => {
                    if (res.data.status) {
                        this.display_form = 0;
                        this.$toaster.success("Settings has been updated successfully");
                        this.clearForm();
                        this.fetchOffers();
                    } else {
                        this.$toaster.error(res.data.message);
                    }
                })
                .catch((error) => {
                    this.error_message = "";
                    this.errors = new ErrorHandling();
                    if (error.response.status == 422) {
                        if (error.response.data.status == "Error") {
                            this.error_message = error.response.data.message;
                        } else {
                            this.errors.record(error.response.data.errors);
                        }
                    }
                })
                .finally(() => (this.$parent.loading = false));
        },
        editOffer(offer) {
            this.edit = true;
            this.display_form = 1;
            this.errors = new ErrorHandling();
            this.offers.id = offer.id;
            this.offers.top_offer_text = offer.top_offer_text;
            this.top_offer_text = offer.top_offer_text;
        },
        clearForm() {
            this.edit = false;
            this.display_form = 0;
            this.offers.id = null;
            this.offers.top_offer_text = "";
            this.top_offer_text = "";
        },
        sorting(sortBy) {
            this.sortBy = sortBy;
            this.sortType =
                this.sortType == "asc" || this.sortType == "ASC"
                    ? (this.sortType = "desc")
                    : (this.sortType = "asc");
            this.fetchOffers();
        },
        setSelectedLanguage(selectedLanguage) {
            this.selectedLanguage = selectedLanguage;
            console.log("i am clicked", selectedLanguage);
        },


        fetchpages(page_url) {
            this.$parent.loading = true;
            let vm = this;
            page_url = page_url || "/api/admin/pages";
            var arr = page_url.split("?");

            if (arr.length > 1) {
                page_url += "&limit=20000";
            } else {
                page_url += "?limit=20000";
            }

            page_url += "&getDetail=1";
            var responseData = {};

            axios
                .get(page_url, this.token)
                .then((res) => {
                    this.pages = res.data.data;
                })
                .finally(() => (this.$parent.loading = false));
        },
        clearSearch() {
            (this.searchParameter = ""), this.fetchOffers();
        },
    },
    mounted() {
        var token = localStorage.getItem("token");
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        this.fetchOffers();
        this.fetchpages();
    },
};
</script>
