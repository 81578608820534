var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
              _c(
                "div",
                { staticClass: "card card-custom gutter-b bg-white border-0" },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [_vm._v("Email")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.email,
                              expression: "user.email",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "email" },
                          domProps: { value: _vm.user.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.user, "email", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.name,
                              expression: "user.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.user.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.user, "name", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.has("name")
                          ? _c("small", {
                              staticClass: "form-text text-danger",
                              domProps: {
                                textContent: _vm._s(_vm.errors.get("name")),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [_vm._v("Password")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.password,
                              expression: "user.password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.user.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.user,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function ($event) {
                              return _vm.updateProfile()
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "\n                                            Profile\n                                        "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }