var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex flex-column-fluid" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card card-custom gutter-b bg-white border-0",
                    },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", [
                          _c("form", { attrs: { id: "myform" } }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleImageSelect1()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                                Upload Media\n                                                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "clearfix" }),
                                      _vm._v(" "),
                                      _vm.gallary_path_1 == null ||
                                      _vm.gallary_path_1 == ""
                                        ? _c(
                                            "small",
                                            {
                                              staticClass:
                                                "form-text text-muted",
                                              attrs: { id: "textHelp" },
                                            },
                                            [
                                              _vm._v(
                                                "Select\n                                                                Image file from gallary."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.errors.has("gallary_id")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get("gallary_id")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.gallary_path_1 != ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                            },
                                            attrs: { src: _vm.gallary_path_1 },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.home_info.home_title_1,
                                            expression:
                                              "home_info.home_title_1",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.home_info.home_title_1,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.home_info,
                                              "home_title_1",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("home_title_1")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get("home_title_1")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Sub Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.home_info.home_sub_title_1,
                                            expression:
                                              "home_info.home_sub_title_1",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.home_info.home_sub_title_1,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.home_info,
                                              "home_sub_title_1",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("home_sub_title_1")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get(
                                                  "home_sub_title_1"
                                                )
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleImageSelect2()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                                Upload Media\n                                                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "clearfix" }),
                                      _vm._v(" "),
                                      _vm.gallary_path_2 == null ||
                                      _vm.gallary_path_2 == ""
                                        ? _c(
                                            "small",
                                            {
                                              staticClass:
                                                "form-text text-muted",
                                              attrs: { id: "textHelp" },
                                            },
                                            [
                                              _vm._v(
                                                "Select\n                                                                Image file from gallary."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.errors.has("gallary_id")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get("gallary_id")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.gallary_path_2 != ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                            },
                                            attrs: { src: _vm.gallary_path_2 },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.home_info.home_title_2,
                                            expression:
                                              "home_info.home_title_2",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.home_info.home_title_2,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.home_info,
                                              "home_title_2",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("home_title_2")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get("home_title_2")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Sub Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.home_info.home_sub_title_2,
                                            expression:
                                              "home_info.home_sub_title_2",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.home_info.home_sub_title_2,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.home_info,
                                              "home_sub_title_2",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("home_sub_title_2")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get(
                                                  "home_sub_title_2"
                                                )
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleImageSelect3()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                                Upload Media\n                                                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "clearfix" }),
                                      _vm._v(" "),
                                      _vm.gallary_path_3 == null ||
                                      _vm.gallary_path_3 == ""
                                        ? _c(
                                            "small",
                                            {
                                              staticClass:
                                                "form-text text-muted",
                                              attrs: { id: "textHelp" },
                                            },
                                            [
                                              _vm._v(
                                                "Select\n                                                                Image file from gallary."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.errors.has("gallary_id")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get("gallary_id")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.gallary_path_3 != ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                            },
                                            attrs: { src: _vm.gallary_path_3 },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.home_info.home_title_3,
                                            expression:
                                              "home_info.home_title_3",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.home_info.home_title_3,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.home_info,
                                              "home_title_3",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("home_title_3")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get("home_title_3")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Sub Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.home_info.home_sub_title_3,
                                            expression:
                                              "home_info.home_sub_title_3",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.home_info.home_sub_title_3,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.home_info,
                                              "home_sub_title_3",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("home_sub_title_3")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get(
                                                  "home_sub_title_3"
                                                )
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleImageSelect4()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                                                Upload Media\n                                                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "clearfix" }),
                                      _vm._v(" "),
                                      _vm.gallary_path_4 == null ||
                                      _vm.gallary_path_4 == ""
                                        ? _c(
                                            "small",
                                            {
                                              staticClass:
                                                "form-text text-muted",
                                              attrs: { id: "textHelp" },
                                            },
                                            [
                                              _vm._v(
                                                "Select\n                                                                Image file from gallary."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.errors.has("gallary_id")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get("gallary_id")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.gallary_path_4 != ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                            },
                                            attrs: { src: _vm.gallary_path_4 },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.home_info.home_title_4,
                                            expression:
                                              "home_info.home_title_4",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.home_info.home_title_4,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.home_info,
                                              "home_title_4",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("home_title_4")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get("home_title_4")
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Sub Title")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.home_info.home_sub_title_4,
                                            expression:
                                              "home_info.home_sub_title_4",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.home_info.home_sub_title_4,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.home_info,
                                              "home_sub_title_4",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("home_sub_title_4")
                                        ? _c("small", {
                                            staticClass:
                                              "form-text text-danger",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.errors.get(
                                                  "home_sub_title_4"
                                                )
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                staticStyle: { "margin-top": "85px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addUpdateHome()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                                Submit\n                                            "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal1 },
        on: {
          toggleImageSelect: _vm.toggleImageSelect1,
          setImage: _vm.setImage1,
        },
      }),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal2 },
        on: {
          toggleImageSelect: _vm.toggleImageSelect2,
          setImage: _vm.setImage2,
        },
      }),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal3 },
        on: {
          toggleImageSelect: _vm.toggleImageSelect3,
          setImage: _vm.setImage3,
        },
      }),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal4 },
        on: {
          toggleImageSelect: _vm.toggleImageSelect4,
          setImage: _vm.setImage4,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "\n                                            Home info setting\n                                        "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }