var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("label", [_vm._v(" ")]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "switch-h d-flex justify-content-between align-items-center border p-2",
      },
      [
        _c("h4", { staticClass: "font-size-h4 text-dark mb-0" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "custom-control switch custom-switch-info custom-switch custom-control-inline mr-0",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value",
                },
              ],
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                id: "switch" + _vm.name,
                name: _vm.name,
              },
              domProps: {
                checked: Array.isArray(_vm.value)
                  ? _vm._i(_vm.value, null) > -1
                  : _vm.value,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.value,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.value = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.value = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.value = $$c
                    }
                  },
                  _vm.updateValue,
                ],
              },
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "custom-control-label mr-1",
              attrs: { for: "switch" + _vm.name },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }