<template>
<div class="form-group row">
   
    <div class="col-md-6">
        <label> 
            <h4>
                        Announcement Description
                    </h4>
                    </label>
        <fieldset class="form-group mb-3">
            <textarea type="text" class="form-control border-dark" cols="12" rows="12" v-model="webgeneral.general_announcements_description"> </textarea>
        </fieldset>    
    </div>

 
	 <div class="col-md-6">
        <label>  <h4>
                        Announcement Image
                    </h4></label>
        <fieldset class="form-group mb-3">
        <div class="form-group">
            <button type="button" class="btn btn-primary" @click="toggleImageSelect()">Select Announcement Image gallary</button>
            <small id="textHelp" class="form-text text-muted">Select Announcement Image gallary.</small>
            <small class="form-text text-danger" v-if="errors.has('gallary_id')" v-text="errors.get('gallary_id')"></small>

            <img v-if="webgeneral.general_announcements_image != ''" :src="webgeneral.general_announcements_image" style="width:100px;height:100px;"/>
        </div>
        </fieldset>
  
        <ul class="list-unstyled mb-0 login-forms">
            <li class="mr-2 mb-1">
            <fieldset>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" name="general_announcements_active" id="general_announcements_active"  @change="check($event)" v-bind:checked="webgeneral.general_announcements_active == '1' ? 'checked':''"><label class="custom-control-label" for="general_announcements_active" >
                    <h4>
                        Announcement Active
                    </h4>
                
                </label></div>
            </fieldset>
        </li>
        </ul>
    </div>

  
	

    <div class="col-md-12">
		<br />
        <button @click="updateSetting()" type="submit" class="btn btn-primary">Submit</button>
    </div>
    <attach-image @toggleImageSelect="toggleImageSelect" :showModal="showModal" @setImage="setImage"/>
    <attach-image @toggleImageSelect="toggleImageSelectIcon" :showModal="showModalIcon" @setImage="setIcon"/>

</div>
</template>

<script>
import ErrorHandling from '../../../ErrorHandling'
export default {
    data() {
        return {
            webgeneral: {
                general_announcements_active: 0,
                general_announcements_image: "",
                general_announcements_description:" ",
              
            },
            showModal:false,
            showModalIcon:false,
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        };
    },

    methods: {
        check: function(e) {
            this.webgeneral[e.target.name] = this.webgeneral[e.target.name] == '1' ? '0' : '1';
        },
        fetchSetting() {
            this.$emit('updateLoadingState', true);

            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            var responseData = {};

            axios.get('/api/admin/setting?type=website_general', config)
                .then(res => {
                    for (var i = 0; i < res.data.data.length; i++) {
                        Object.assign(responseData, {
                            [res.data.data[i].setting_key]: res.data.data[i].setting_value
                        });
                    }
                    console.log('response datass', responseData);
                    this.webgeneral = responseData;
                })
                .finally(() => (this.$emit('updateLoadingState', false)));
        },

        updateSetting() {
            this.$emit('updateLoadingState', true);
            var webgeneral = Object.entries(this.webgeneral);
            var key = [];
            var value = [];

            for (var i = 0; i < webgeneral.length; i++) {
                key.push(webgeneral[i][0]);
                value.push(webgeneral[i][1])
            }
            

            // console.log(key, value);

            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            axios.post('/api/admin/setting/website_general', {
                    _method: 'PUT',
                    key,
                    value
                }, config)
                .then(res => {
                    if (res.data.status == "Success") {
						this.$toaster.success('Settings has been updated successfully')
                    }
                    else if(res.data.status == 'Error'){
                        this.$toaster.error(res.data.message)
                    }
                    
                })
                .catch(err => {
                    if(err.response.data.status == 'Error'){
                        this.$toaster.error(err.response.data.message)
                    }
                })
                .finally(() => (this.$emit('updateLoadingState', false)));

        },
        toggleImageSelect(){
            this.showModal = !this.showModal;
        },
        setImage(gallary){
           this.webgeneral.general_announcements_image = gallary.gallary_path;
        },
      
    },
    mounted() {
        this.fetchSetting();
    }
};
</script>
