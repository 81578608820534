<template>
    <div>
        <label>&nbsp;</label>
        <div class="
            switch-h
            d-flex
            justify-content-between
            align-items-center
            border
            p-2
        ">
            <h4 class="font-size-h4 text-dark mb-0">{{ title }}</h4>
            <div class="
                custom-control
                switch
                custom-switch-info custom-switch custom-control-inline
                mr-0
            ">
                <input type="checkbox" :id="'switch' + name" class="custom-control-input" :name="name" v-model="value"
                    @change="updateValue">
                <label :for="'switch' + name" class="custom-control-label mr-1"></label>
            </div>
        </div> <!---->
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        name: String,
        value: Number
    },
    emits: ['updateValue'],
    methods: {
        updateValue() {
            this.$emit('updateValue', this.value == false ? 0 : 1, this.name);
        }
    }
}
</script>
