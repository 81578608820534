var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "offcanvas offcanvas-right kt-color-panel p-5",
      attrs: { id: "kt_color_panel" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "offcanvas-header d-flex align-items-center justify-content-between pb-3",
        },
        [
          _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
            _vm._v("Theme Config\n\t\t\t"),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-sm btn-icon btn-light btn-hover-primary",
              attrs: { href: "#", id: "kt_color_panel_close" },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "bi bi-x",
                  attrs: {
                    width: "20px",
                    height: "20px",
                    viewBox: "0 0 16 16",
                    fill: "currentColor",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "offcanvas-content" }, [
      _c(
        "div",
        {
          staticClass: "customizer-theme-layout",
          attrs: { id: "customizer-theme-layout" },
        },
        [
          _c("h5", { staticClass: "mt-1" }, [_vm._v("Theme Layout")]),
          _vm._v(" "),
          _c("div", { staticClass: "theme-layout" }, [
            _c("div", { staticClass: "d-flex justify-content-start" }, [
              _c("div", { staticClass: "my-3" }, [
                _c("div", { staticClass: "btn-group btn-group-toggle" }, [
                  _c("label", { staticClass: "btn btn-primary p-2 active" }, [
                    _c("input", {
                      attrs: {
                        type: "radio",
                        name: "layoutOptions",
                        value: "false",
                        id: "radio-light",
                        checked: "",
                      },
                    }),
                    _vm._v("\n\t\t\t\t\t\t\t\t\tLight\n\t\t\t\t\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "btn btn-primary p-2" }, [
                    _c("input", {
                      attrs: {
                        type: "radio",
                        name: "layoutOptions",
                        value: "false",
                        id: "radio-dark",
                      },
                    }),
                    _vm._v(" Dark\n\t\t\t\t\t\t\t\t"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h5", { staticClass: "mt-1" }, [_vm._v("RTL Layout")]),
          _vm._v(" "),
          _c("div", { staticClass: "rtl-layout" }, [
            _c("div", { staticClass: "d-flex justify-content-start" }, [
              _c("div", { staticClass: "my-3 btn-rtl" }, [
                _c("div", { staticClass: "toggle" }, [
                  _c("span", { staticClass: "circle" }),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "customizer-theme-colors",
          attrs: { id: "customizer-theme-colors" },
        },
        [
          _c("h5", [_vm._v("Theme Colors")]),
          _vm._v(" "),
          _c("ul", { staticClass: "list-inline unstyled-list d-flex" }, [
            _c("li", { staticClass: "color-box mr-2" }, [
              _c("div", {
                staticClass: "d-flex rounded w-20px h-20px",
                staticStyle: { "background-color": "#ae69f5d9" },
                attrs: { id: "color-theme-default" },
              }),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "color-box mr-2" }, [
              _c("div", {
                staticClass: "d-flex rounded w-20px h-20px",
                staticStyle: { "background-color": "blue" },
                attrs: { id: "color-theme-blue" },
              }),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "color-box mr-2" }, [
              _c("div", {
                staticClass: "d-flex rounded w-20px h-20px",
                staticStyle: { "background-color": "red" },
                attrs: { id: "color-theme-red" },
              }),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "color-box mr-2" }, [
              _c("div", {
                staticClass: "d-flex rounded w-20px h-20px",
                staticStyle: { "background-color": "green" },
                attrs: { id: "color-theme-green" },
              }),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "color-box mr-2" }, [
              _c("div", {
                staticClass: "d-flex rounded w-20px h-20px",
                staticStyle: { "background-color": "#ffc107" },
                attrs: { id: "color-theme-yellow" },
              }),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "color-box mr-2" }, [
              _c("div", {
                staticClass: "d-flex rounded w-20px h-20px",
                staticStyle: { "background-color": "#1b222e080" },
                attrs: { id: "color-theme-navy-blue" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }