<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Admin Notifications
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div>
                                            <div class=" table-responsive" id="printableTable">

                                                <div id="UserTable_wrapper" class="dataTables_wrapper no-footer">

                                                    <div class="dataTables_length" id="UserTable_length"><label>Show
                                                            <select name="UserTable_length" class="" v-model="limit"
                                                                v-on:change="fetchNotifications()">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                                <option value="200">200</option>
                                                                <option value="500">500</option>
                                                                <option value="1000">1000</option>
                                                            </select> entries</label></div>

                                                    <div id="UserTable_filter" class="dataTables_filter">
                                                        <label>Search:<input type="text" class="" placeholder=""
                                                                v-model="searchParameter" @keyup="fetchNotifications()"></label>
                                                        <button v-if="this.searchParameter != ''"
                                                            @click="clearSearch">clear</button>
                                                    </div>
                                                    <table id="UserTable" class="display dataTable no-footer" role="grid">
                                                        <thead class="text-body">
                                                            <tr role="row">
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-sort="ascending"
                                                                    aria-label="ID: activate to sort column descending"
                                                                    style="width: 31.25px;" @click="sorting('id')"
                                                                    :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'id' ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'id' ? 'sorting_desc' : 'sorting'">
                                                                    ID
                                                                </th>
                                                               
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-label="user: activate to sort column ascending"
                                                                    style="width: 95.5288px;" @click="sorting('name')"
                                                                    :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'name' ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'name' ? 'sorting_desc' : 'sorting'">
                                                                    E-mail
                                                                </th>
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-label="user: activate to sort column ascending"
                                                                    style="width: 95.5288px;" @click="sorting('name')"
                                                                    :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'name' ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'name' ? 'sorting_desc' : 'sorting'">
                                                                    Phone
                                                                </th>
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-label="Phone No: activate to sort column ascending"
                                                                    style="width: 81.8109px;" @click="sorting('email')"
                                                                    :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'email' ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'email' ? 'sorting_desc' : 'sorting'">
                                                                    Title
                                                                </th>
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-label="Email: activate to sort column ascending"
                                                                    style="width: 114.84px;">
                                                                    Message
                                                                </th>
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-label="Email: activate to sort column ascending"
                                                                    style="width: 114.84px;">
                                                                    Status
                                                                </th>
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-label="Email: activate to sort column ascending"
                                                                    style="width: 114.84px;">
                                                                    Created_at
                                                                </th>
                                                                <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                    aria-label="Email: activate to sort column ascending"
                                                                    style="width: 114.84px;">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="kt-table-tbody text-dark">
                                                            <tr class="kt-table-row kt-table-row-level-0 odd" role="row"
                                                                v-for="(notification, index) in notifications" v-bind:key="index">
                                                                <td class="sorting_1">
                                                                    {{ ++index }}
                                                                </td>
                                                               
                                                                <td>
                                                                    {{ notification.email }}
                                                                </td>
                                                                <td>
                                                                    {{ notification.phone }}
                                                                </td>
                                                                <td>
                                                                    {{ notification.title }}
                                                                </td>
                                                                
                                                                <td>
                                                                    {{ notification.message }}
                                                                </td>

                                                               
                                                                <td>
                                                                    <span v-if="notification.status === 1" class="badge badge-danger">Read done</span>
                                                                    <span v-else-if="notification.status === 0" class="badge badge-success">New Message</span>
                                                                </td>
                                                                <td>
                                                                    {{ notification.created_at }}
                                                                </td>
                                                                <td>
                                                                    <span id="notification_remove" class="ml-2" @click="clearNotification(index,notification.id)">
                                                                   <i class="fas fa-trash"></i>
                                                                   </span>
                                                                   
                                                                   <span id="update_status" class="ml-2" @click="update_status(notification.id)">
                                                                   <i class="fa fa-file-edit text-success" style="cursor: pointer;color: aqua;"></i>
                                                                   </span>
                                                              </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <ul class="pagination pagination-sm m-0 float-right">
                                                        <li v-bind:class="[{ disabled: !pagination.prev_page_url }]"><button
                                                                class="page-link" href="#"
                                                                @click="fetchNotifications(pagination.prev_page_url)">Previous</button>
                                                        </li>

                                                        <li class="disabled"><a class="page-link text-dark" href="#">Page {{
                                                            pagination.current_page }} of {{ pagination.last_page }}</a>
                                                        </li>

                                                        <li v-bind:class="[{ disabled: !pagination.next_page_url }]"
                                                            class="page-item"><button class="page-link" href="#"
                                                                @click="fetchNotifications(pagination.next_page_url)">Next</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ErrorHandling from "./../../ErrorHandling";
export default {
    components: { Multiselect },
    data() {
        return {
            display_form: 0,
            notification : {
                title: '',
                message: '',
                email: '',
                phone: '',
                created_at: '',
                users: [],
                customers: [],
                image: Object,
                selectAllUsers: false,
                selectAllCustomers: false,
            },
            notifications: {},
            searchParameter: '',
            sortBy: 'id',
            sortType: 'DESC',
            limit: 10,
            error_message: '',
            edit: false,
            actions: false,
            pagination: {},
            request_method: "",
            users: [],
            customers: [],
            roles: [],
            token: [],
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            selectedImageUrl: '',
            selectedItems: [],
        };
    },

    methods: {
       

        fetchNotifications(page_url) {
            this.$parent.loading = true;
            let vm = this;
            page_url = page_url || "/api/admin/get-admin_notifications";
            var arr = page_url.split('?');

            if (arr.length > 1) {
                page_url += '&limit=' + this.limit;
            }
            else {
                page_url += '?limit=' + this.limit;
            }
            if (this.searchParameter != null) {
                page_url += '&searchParameter=' + this.searchParameter;
            }
            page_url += '&sortBy=' + this.sortBy + '&sortType=' + this.sortType;
            var responseData = {};

            axios.get(page_url, this.token).then(res => {
                this.notifications = res.data.data;
                vm.makePagination(res.data.meta, res.data.links);
            }).finally(() => (this.$parent.loading = false));
        },

    
       
        makePagination(meta, links) {
            let pagination = {
                current_page: meta.current_page,
                last_page: meta.last_page,
                next_page_url: links.next,
                prev_page_url: links.prev
            };

            this.pagination = pagination;
        },

 
        sorting(sortBy) {
            this.sortBy = sortBy;
            this.sortType = this.sortType == 'asc' || this.sortType == 'ASC' ? this.sortType = 'desc' : this.sortType = 'asc';
            this.fetchNotifications();
        },

        nameWithLang(user) {
            return user ? `${user.name}` : 'Hey';
        },
        nameWithCustomer(customer) {
            return customer ? `${customer.customer_first_name + ' ' + customer.customer_last_name}` : 'Hey';
        },
        clearSearch() {
            this.searchParameter = "",
                this.fetchNotifications();
        },
        update_status(id) {
            // this.notifications.splice(index, 1);

            this.url = "/api/admin/update_notifications/"+id;
            axios.patch(this.url,{id:id},this.token).then(res => {
                this.fetchNotifications();

            }).catch(error => {
                // Handle error if needed
            });

        },
        clearNotification(index, id) {
            this.notifications.splice(index, 1);

            this.url = "/api/admin/delete_notifications/"+id;
            
            // Include the id in the request payload
            axios.delete(this.url,this.token).then(res => {
                this.fetchNotifications();

            }).catch(error => {
                // Handle error if needed
            });


        },
       
    },
       
    mounted() {

        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        this.fetchNotifications();
       
    }
};
</script>


<style scoped>
    .check-input {
        width: 8% !important;
        margin-left: 20px !important;
    }

    .check-label {
        margin-top: 6px !important;
    }
</style>
