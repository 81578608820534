
<template>
    <!--begin::Header-->
        <div id="tc_header" class="header header-fixed">
            <!--begin::Container-->
            <div class="container-fluid d-flex align-items-stretch justify-content-between">
                <!--begin::Header Menu Wrapper-->
                <div class="header-menu-wrapper header-menu-wrapper-left" id="tc_header_menu_wrapper">
                    <!--begin::Header Menu-->
                    <div id="tc_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                        <!--begin::Header Nav-->
                        <ul class="menu-nav">
    
                                <li class="menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here menu-item-active p-0"
                                data-menu-toggle="click" aria-haspopup="true" @click="setBurgerMenu()">
                                <!--begin::Toggle-->
                                <div class="btn  btn-clean btn-dropdown mr-0 p-0" id="tc_aside_toggle">
                                    <span class="svg-icon svg-icon-xl svg-icon-primary">
    
                                        <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-list"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                        </svg>
                                    </span>
                                </div>
                                <!--end::Toolbar-->
                            </li>
    
                        </ul>
                        <!--end::Header Nav-->
                    </div>
                    <!--end::Header Menu-->
                </div>
                <!--end::Header Menu Wrapper-->
                <!--begin::Topbar-->
                
                <div class="topbar">
                    <div class="posicon d-lg-block d-none" v-if="$parent.permissions.includes('pos')">
                        <router-link to="/admin/pos" class="btn btn-primary white mr-2">POS</router-link>
                    </div>
    
    
    <!-- Notification Dropdown Menu -->
    <div class="posicon d-lg-block d-none">
        <div class="dropdown" :class="{ 'show': show_notification }">
            <div class="topbar-item" data-toggle="dropdown" data-display="static" :aria-expanded="show_notification"
                @click="showNotification()">
                <!-- Notification Icon with Count -->
                <div class="btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3 position-relative">
                    <span class="text-dark-50 font-size-base d-none d-xl-inline mr-3">
                        Notifications
                    </span>
                    <span class="symbol symbol-35 symbol-light-success position-relative">
                        <span class="symbol-label font-size-h5">
                            <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-bell" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 16a2 2 0 0 1-2-2H10a2 2 0 0 1-2 2zm6-3V8a6 6 0 0 0-6-6V1a1 1 0 0 0-2 0v1a6 6 0 0 0-6 6v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                                <path fill-rule="evenodd"
                                    d="M1 9a8 8 0 0 1 8-8v1a7 7 0 0 0-7 7H1V9zm14 0H2v1h13a7 7 0 0 0 7-7v-1a8 8 0 0 1-8 8z" />
                            </svg>
                        </span>
                        <!-- Notification Count Badge -->
                        <span class="badge badge-pill badge-danger position-absolute top-0 start-100 translate-middle">
                            {{ notifications.length }}
                        </span>
                    </span>
                </div>
            </div>
            <!-- Notification Items -->
    <div v-if="show_notification" class="dropdown-menu dropdown-menu-right show" style="min-width: 340px; margin-top: 15px; border-radius: 10px 10px 0px 0px;padding-bottom: 0px;padding: 0px;">          
        
        <template v-for="(notification, index) in notifications">
        <li :key="index" class="list-group-item d-flex justify-content-between align-items-center">
            <!-- Notification Text -->
            <span style="font-size: 13px; font-weight: 450;">{{ notification.title }}</span>
            
            <!-- Icon for clearing notification -->
            <span id="notification_remove" class="ml-2" @click="clearNotification(index,notification.id)">
                <i class="fas fa-trash"></i>
            </span>
        </li>
    </template>
    
    
               
        <button class="btn btn-lg  btn-block" style="background-color: #a965ee; color: #fff;" @click="showAllNotifications">Show All Notifications</button>
                <!-- If there are no notifications, display a message -->
                <template v-if="notifications.length === 0">
                    <span class="dropdown-item">No notifications</span>
                </template>
                <!-- Clear Notifications Button -->
            </div>
        </div>
    </div>
    
    
    
    
    
    
    
    
    
    
                    <div class="posicon d-lg-block d-none">
                        <div class="dropdown" :class="{ 'show': showdropdown }">
    
                                    <div class="topbar-item" data-toggle="dropdown" data-display="static" :aria-expanded="showdropdown" @click="showDropDown()">
                                        <div class="btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3">
                                            <span class="text-dark-50 font-size-base d-none d-xl-inline mr-3">
                                                {{ username }}</span>
                                            <span class="symbol symbol-35 symbol-light-success" >
                                                <span class="symbol-label font-size-h5 " >
                                                    <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
    
                                    <div class="dropdown-menu dropdown-menu-right" :class="{ 'show': showdropdown }" style="min-width: 150px;">
    
                                        <router-link to="/admin/profile" class="dropdown-item">
                                            <span class="svg-icon svg-icon-xl svg-icon-primary mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                            </span>
                                            Edit Profile
                                        </router-link>
    
                                        <a href="#" class="dropdown-item" @click="logOut()">
                                            <span class="svg-icon svg-icon-xl svg-icon-primary mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power">
                                                    <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                                    <line x1="12" y1="2" x2="12" y2="12"></line>
                                                </svg>
                                            </span>
                                            Logout
                                        </a>
                                    </div>
    
                                </div>
                    </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
                </div>
                <!--end::Topbar-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Header-->
    </template>
    
  
    
    <script>
    import Echo from 'laravel-echo';
    
    export default {
       data(){
           return {
               showdropdown:false,
               username:"",
               show_notification:false,
               notifications:[],
               url:'',
               token: [],
               csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    
           }
           
       },
        methods: {
            
            logOut() {
                this.$parent.loading = true;
                let page_url = "/api/admin/logout";
    
                axios.post(page_url,{},this.token).then(res => {
                    this.orders = res.data.data;
                    setTimeout(() => { this.$router.push('/admin/login'); }, 1000);
                })
            },
            setBurgerMenu(){
                
                var classList = document.body.classList.value.split(' ');
                console.log(classList);
                if(classList.indexOf('aside-minimize') === -1)
                    document.body.classList.add('aside-minimize')
                else
                    document.body.classList.remove('aside-minimize')
    
                this.$emit('setBurgerMenu');
            },
            showDropDown(){
                this.showdropdown = !this.showdropdown
    
            },
            showNotification(){
                this.show_notification = !this.show_notification
    
            },
            clearNotification(index, id) {
        this.notifications.splice(index, 1);
    
        this.url = "/api/admin/delete_notifications/"+id;
        
        // Include the id in the request payload
        axios.delete(this.url,this.token).then(res => {
            // Handle response if needed
        }).catch(error => {
            // Handle error if needed
        });
    
    
            },
            markAsRead(index) {
                // Implement logic to mark notification as read
            },
            showAllNotifications(){
                this.$router.push('/admin/admin-notifications');
            },
            getNotification(){
    
            },
            fetchNotifications() {
        this.url = "/api/admin/get-admin_notifications?status=0";
        
        axios.get(this.url, this.token).then(res => {
            this.notifications = res.data.data.map(notification => {
                return { ...notification };
            });
        });
    },
    
        },
        mounted() {
    window.Pusher = require('pusher-js');
    Pusher.logToConsole = true;

    // Initialize Laravel Echo
    const echo  = new Echo({
        broadcaster: 'pusher',
        key: '579428a83b7e1844281c',
        cluster:'mt1',
        // Add other options as needed
    });

    // Listen for AdminNotification events
    echo.channel('Admin_Notification')
        .listen('AdminNotification', (event) => {
            // Increment notification count
            this.notificationCount++;
            // Prepend new notification to notifications array
            const newNotification = { title: event.title };
        // Prepend the new notification to notifications array
        this.notifications.unshift(newNotification);
        });




    var token = localStorage.getItem('token');
    this.token = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    this.fetchNotifications();

    console.log(localStorage.getItem('name'));
    if(localStorage.getItem('name')){
        this.username = localStorage.getItem('name')
    }
},

    };
    </script>
    <style>
    #notification_remove{
        cursor: pointer;
        color: rgb(226, 92, 92);
    
    }
    #notification_read{
        cursor: pointer;
        color: rgb(86, 228, 86);
    
    }
    
    #notification_remove:hover{
        cursor: pointer;
        color: red;
    
    }
    #notification_read:hover{
        cursor: pointer;
        color: green;
    
    }
    .list-group-item:nth-child(even) {
          background-color: #f8f9fa; /* Set the background color for even-indexed items */
        }
    </style>