var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-custom gutter-b bg-white border-0" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("form", [
              _c(
                "div",
                { staticClass: "form-group row" },
                [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "switch-h d-flex justify-content-between align-items-center border p-2 mb-3",
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is Active?")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-control switch custom-switch-info custom-switch custom-control-inline mr-0",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.product_status,
                                  expression: "product_status",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "customSwitchcolor445",
                              },
                              domProps: {
                                value: _vm.product_status,
                                checked: Array.isArray(_vm.product_status)
                                  ? _vm._i(
                                      _vm.product_status,
                                      _vm.product_status
                                    ) > -1
                                  : _vm.product_status,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setProductStatus(
                                    $event.target.value
                                  )
                                },
                                change: function ($event) {
                                  var $$a = _vm.product_status,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.product_status,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.product_status = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.product_status = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.product_status = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "customSwitchcolor445" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("product_status")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("product_status")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "switch-h d-flex justify-content-between align-items-center border p-2 mb-3",
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is Show In home ?")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-control switch custom-switch-info custom-switch custom-control-inline mr-0",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_show_in_home,
                                  expression: "is_show_in_home",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: { type: "checkbox", id: "show_in_home" },
                              domProps: {
                                value: _vm.is_show_in_home,
                                checked: Array.isArray(_vm.is_show_in_home)
                                  ? _vm._i(
                                      _vm.is_show_in_home,
                                      _vm.is_show_in_home
                                    ) > -1
                                  : _vm.is_show_in_home,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setIsShowHome($event.target.value)
                                },
                                change: function ($event) {
                                  var $$a = _vm.is_show_in_home,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.is_show_in_home,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_show_in_home = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_show_in_home = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_show_in_home = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "show_in_home" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_show_in_home")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("is_show_in_home")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "switch-h d-flex justify-content-between align-items-center border p-2 mb-3",
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is Point")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-control switch custom-switch-info custom-switch custom-control-inline mr-0",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_points,
                                  expression: "is_points",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: { type: "checkbox", id: "points" },
                              domProps: {
                                value: _vm.is_points,
                                checked: Array.isArray(_vm.is_points)
                                  ? _vm._i(_vm.is_points, _vm.is_points) > -1
                                  : _vm.is_points,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setIsPoints($event.target.value)
                                },
                                change: function ($event) {
                                  var $$a = _vm.is_points,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.is_points,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_points = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_points = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_points = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "points" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_points")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("is_points")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "switch-h d-flex justify-content-between align-items-center border p-2 mb-3",
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is Feature")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-control switch custom-switch-info custom-switch custom-control-inline mr-0",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_featured,
                                  expression: "is_featured",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: { type: "checkbox", id: "features" },
                              domProps: {
                                value: _vm.is_featured,
                                checked: Array.isArray(_vm.is_featured)
                                  ? _vm._i(_vm.is_featured, _vm.is_featured) >
                                    -1
                                  : _vm.is_featured,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setIsFeatured($event.target.value)
                                },
                                change: function ($event) {
                                  var $$a = _vm.is_featured,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.is_featured,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_featured = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_featured = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_featured = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "features" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_featured")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("is_featured")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "switch-h d-flex justify-content-between align-items-center border p-2 mb-3",
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is Atimer")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-control switch custom-switch-info custom-switch custom-control-inline mr-0",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_atimer,
                                  expression: "is_atimer",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: { type: "checkbox", id: "timer" },
                              domProps: {
                                value: _vm.is_atimer,
                                checked: Array.isArray(_vm.is_atimer)
                                  ? _vm._i(_vm.is_atimer, _vm.is_atimer) > -1
                                  : _vm.is_atimer,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setIsTimer($event.target.value)
                                },
                                change: function ($event) {
                                  var $$a = _vm.is_atimer,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.is_atimer,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_atimer = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_atimer = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_atimer = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "timer" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_atimer")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("is_atimer")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "switch-h d-flex justify-content-between align-items-center border p-2 mb-3",
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "font-size-h4 text-dark mb-0" },
                          [_vm._v("Is 50-80 OFF")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "custom-control switch custom-switch-info custom-switch custom-control-inline mr-0",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.is_afifty_to_eighty,
                                  expression: "is_afifty_to_eighty",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: { type: "checkbox", id: "fifty" },
                              domProps: {
                                value: _vm.is_afifty_to_eighty,
                                checked: Array.isArray(_vm.is_afifty_to_eighty)
                                  ? _vm._i(
                                      _vm.is_afifty_to_eighty,
                                      _vm.is_afifty_to_eighty
                                    ) > -1
                                  : _vm.is_afifty_to_eighty,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setIsFiftyToEighty(
                                    $event.target.value
                                  )
                                },
                                change: function ($event) {
                                  var $$a = _vm.is_afifty_to_eighty,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm.is_afifty_to_eighty,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.is_afifty_to_eighty = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.is_afifty_to_eighty = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.is_afifty_to_eighty = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              staticClass: "custom-control-label mr-1",
                              attrs: { for: "fifty" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_afifty_to_eighty")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("is_afifty_to_eighty")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Product Type")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.product_type,
                              expression: "product_type",
                            },
                          ],
                          staticClass:
                            "single-select w-100 mb-3 categories-select ms-offscreen",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.product_type = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.setProductType($event.target.value)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "", disabled: "" } }, [
                            _vm._v("Select Product Type"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "simple",
                                disabled:
                                  _vm.product_type == "variable" && _vm.edit,
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        Simple\n                                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "variable",
                                disabled:
                                  _vm.product_type == "simple" && _vm.edit,
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        Variable\n                                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.has("product_type")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.get("product_type")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Units")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.unit_id,
                              expression: "unit_id",
                            },
                          ],
                          staticClass:
                            "single-select w-100 mb-3 categories-select ms-offscreen",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.unit_id = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.setUnit($event.target.value)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Unit"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.units, function (unit) {
                            return _c(
                              "option",
                              { domProps: { value: unit.id } },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(
                                      unit.detail == null
                                        ? ""
                                        : unit.detail[0]
                                        ? unit.detail[0].name
                                        : ""
                                    ) +
                                    "\n                                    "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("unit_id")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("unit_id")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Brands")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.brand_id,
                              expression: "brand_id",
                            },
                          ],
                          staticClass:
                            "single-select w-100 mb-3 categories-select ms-offscreen",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.brand_id = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.setBrand($event.target.value)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Brand"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.brands, function (brand) {
                            return _c(
                              "option",
                              { domProps: { value: brand.brand_id } },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(brand.brand_name) +
                                    "\n                                    "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("brand_id")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("brand_id")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Product Weight")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_weight,
                            expression: "product_weight",
                          },
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "text",
                          id: "weight",
                          placeholder: "Enter Weight",
                        },
                        domProps: { value: _vm.product_weight },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.product_weight = $event.target.value
                            },
                            function ($event) {
                              return _vm.setProductWeight($event.target.value)
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("product_weight")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("product_weight")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Price")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.price,
                            expression: "price",
                          },
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: { type: "number", placeholder: "Enter Price" },
                        domProps: { value: _vm.price },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.price = $event.target.value
                            },
                            function ($event) {
                              return _vm.setPrice($event.target.value)
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("price")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("price")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Discount Price")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.discount,
                            expression: "discount",
                          },
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "number",
                          placeholder: "Enter Discount Price",
                        },
                        domProps: { value: _vm.discount },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.discount = $event.target.value
                            },
                            function ($event) {
                              return _vm.setDiscountPrice($event.target.value)
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("discount")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("discount")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v("Minimum Order ")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_min_order,
                            expression: "product_min_order",
                          },
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "text",
                          id: "type",
                          placeholder: "Enter Minimum Order",
                        },
                        domProps: { value: _vm.product_min_order },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.product_min_order = $event.target.value
                            },
                            function ($event) {
                              return _vm.setProductMinOrder($event.target.value)
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("product_min_order")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("product_min_order")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v("Maximum Order")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product_max_order,
                            expression: "product_max_order",
                          },
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "text",
                          id: "type-max",
                          placeholder: "Enter Maximum Order",
                        },
                        domProps: { value: _vm.product_max_order },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.product_max_order = $event.target.value
                            },
                            function ($event) {
                              return _vm.setProductMaxOrder($event.target.value)
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("product_max_order")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(
                              _vm.errors.get("product_max_order")
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("SKU")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sku,
                            expression: "sku",
                          },
                        ],
                        staticClass:
                          "form-control round bg-transparent text-dark",
                        attrs: {
                          type: "text",
                          id: "type-max",
                          placeholder: "Enter Sku",
                        },
                        domProps: { value: _vm.sku },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.sku = $event.target.value
                            },
                            function ($event) {
                              return _vm.setProductsku($event.target.value)
                            },
                          ],
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("sku")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("sku")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Product option")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.product_option,
                              expression: "product_option",
                            },
                          ],
                          staticClass:
                            "single-select w-100 mb-3 categories-select ms-offscreen",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.product_option = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function ($event) {
                                return _vm.setProductOption($event.target.value)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "", disabled: "" } }, [
                            _vm._v("Select Product Type"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "simple",
                                disabled:
                                  _vm.product_option == "benefit" && _vm.edit,
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        Simple\n                                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "benefit",
                                disabled:
                                  _vm.product_option == "simple" && _vm.edit,
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        Benefit\n                                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "MATERIALS & CONSTRUCTION\t",
                                disabled:
                                  _vm.product_option == "simple" && _vm.edit,
                              },
                            },
                            [
                              _vm._v(
                                "\n                                        MATERIALS & CONSTRUCTION\n                                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.has("product_option")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.get("product_option")
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.product_type == "variable"
                    ? _c("div", { staticClass: "col-md-6" }, [
                        _c("label", [_vm._v("Attributes")]),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          { staticClass: "form-group mb-3 d-flex" },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.attribute,
                                    expression: "attribute",
                                  },
                                ],
                                staticClass:
                                  "single-select w-100 mb-3 categories-select ms-offscreen",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.attribute = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Select Attributes"),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.attributes, function (attributes) {
                                  return _c(
                                    "option",
                                    {
                                      domProps: {
                                        value: attributes.attribute_id,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            attributes.detail == null
                                              ? ""
                                              : attributes.detail[0]
                                              ? attributes.detail[0].name
                                              : ""
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn-secondary btn ml-2 white pt-2 pb-1",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.getVariation()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    ADD\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.displayClearBtn,
                                    expression: "displayClearBtn",
                                  },
                                ],
                                staticClass:
                                  "btn-secondary btn ml-2 white pt-2 pb-1",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.unsetVariationData()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    Remove\n                                "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors.has("attributes")
                          ? _c("small", {
                              staticClass: "form-text text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.errors.get("attributes")
                                ),
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", [_vm._v("Opinions")]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3 d-flex" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.opinion,
                              expression: "opinion",
                            },
                          ],
                          staticClass:
                            "single-select w-100 mb-3 categories-select ms-offscreen",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.opinion = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Opinion"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.opinions, function (opinions) {
                            return _c(
                              "option",
                              { domProps: { value: opinions.opinion_id } },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(
                                      opinions.detail == null
                                        ? ""
                                        : opinions.detail[0]
                                        ? opinions.detail[0].name
                                        : ""
                                    ) +
                                    "\n                                    "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn-secondary btn ml-2 white pt-2 pb-1",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.getBenefit()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                                    ADD\n                                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.displayClearBtns,
                              expression: "displayClearBtns",
                            },
                          ],
                          staticClass: "btn-secondary btn ml-2 white pt-2 pb-1",
                          attrs: {
                            type: "button",
                            disabled: _vm.editChild == true,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.unsetBenefitData()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                                    Remove\n                                "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.errors.has("opinions")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("opinions")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.product_type == "variable"
                    ? _c("div", { staticClass: "col-md-6" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product_option == "benefit"
                    ? _c("div", { staticClass: "col-md-6" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.benefits, function (benefit, index) {
                    return [
                      Number(benefit.show_benefit)
                        ? _c("div", { staticClass: "col-md-6 mb-3" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  benefit.detail == null
                                    ? ""
                                    : benefit.detail[0].name
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              { staticClass: "form-group mb-3 d-flex" },
                              [
                                _c(
                                  "select",
                                  {
                                    staticClass:
                                      "single-select w-100 mb-3 categories-select ms-offscreen",
                                    attrs: { multiple: "" },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "",
                                          selected: "",
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            Select here\n                                            " +
                                            _vm._s(
                                              benefit.detail == null
                                                ? ""
                                                : benefit.detail[0].name
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      benefit.benefits,
                                      function (benefitDetails) {
                                        return _c(
                                          "option",
                                          {
                                            attrs: {
                                              set: (_vm.allBenefits[
                                                benefitDetails.id
                                              ] =
                                                benefitDetails.detail == null
                                                  ? ""
                                                  : benefitDetails.detail[0]
                                                      .name),
                                            },
                                            domProps: {
                                              selected: _vm.benefitData[
                                                "benefit"
                                              ]
                                                ? _vm.benefitData[
                                                    "benefit"
                                                  ].includes(benefitDetails.id)
                                                : false,
                                              value: benefitDetails.id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.updateBenefits(
                                                  _vm.benefitData["benefit"],
                                                  benefitDetails.id
                                                )
                                                _vm.setBenefits(
                                                  "benefit",
                                                  benefit.opinion_id,
                                                  _vm.benefitData["benefit"]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  benefitDetails.detail == null
                                                    ? ""
                                                    : benefitDetails.detail[0]
                                                        .name
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      Number(benefit.show_editor)
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12",
                              staticStyle: { "margin-bottom": "5rem" },
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    benefit.detail == null
                                      ? ""
                                      : benefit.detail[0].name
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "fieldset",
                                {
                                  staticClass: "form-group mb-3 d-flex",
                                  attrs: { id: "parent" },
                                },
                                [
                                  _c("quillEditor", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.setEditor(
                                          _vm.editorValues[index],
                                          benefit.opinion_id
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.editorValues[index],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editorValues, index, $$v)
                                      },
                                      expression: "editorValues[index]",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      Number(benefit.show_gallary_description)
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12",
                              staticStyle: { "margin-bottom": "5rem" },
                            },
                            [
                              Number(benefit.show_gallary_description)
                                ? [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          benefit.detail == null
                                            ? "Show Gallary Description"
                                            : benefit.detail[0].name
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-6" },
                                          [
                                            _c("quillEditor", {
                                              on: {
                                                input:
                                                  _vm.showGallaryDescription,
                                              },
                                              model: {
                                                value:
                                                  _vm.DataGallary_description
                                                    .title,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.DataGallary_description,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "DataGallary_description.title",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-md-6" }, [
                                          _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.DataGallary_description
                                                      .color,
                                                  expression:
                                                    "DataGallary_description.color",
                                                },
                                              ],
                                              attrs: { type: "color" },
                                              domProps: {
                                                value:
                                                  _vm.DataGallary_description
                                                    .color,
                                              },
                                              on: {
                                                change:
                                                  _vm.showGallaryDescription,
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.DataGallary_description,
                                                    "color",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "table",
                                        { staticClass: "styled-table" },
                                        [
                                          _vm._m(1, true),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.DataGallary_description
                                                .Gallary_description,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("quillEditor", {
                                                          on: {
                                                            input:
                                                              _vm.showGallaryDescription,
                                                          },
                                                          model: {
                                                            value: item.title,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "title",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.title",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("quillEditor", {
                                                          on: {
                                                            input:
                                                              _vm.showGallaryDescription,
                                                          },
                                                          model: {
                                                            value:
                                                              item.description,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "description",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.description",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-primary",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toggleImageSelect(
                                                                item.image,
                                                                index,
                                                                "gallary_description"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                Upload Media\n            "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm
                                                        .DataGallary_description
                                                        .Gallary_description[
                                                        index
                                                      ]["image"] !== "" &&
                                                      _vm
                                                        .DataGallary_description
                                                        .Gallary_description[
                                                        index
                                                      ]["image"] !== null
                                                        ? _c("img", {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                            },
                                                            attrs: {
                                                              src: _vm
                                                                .DataGallary_description
                                                                .Gallary_description[
                                                                index
                                                              ]["image"],
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .DataGallary_description
                                                                .Gallary_description[
                                                                index
                                                              ]["image"],
                                                            expression:
                                                              "DataGallary_description.Gallary_description[index]['image']",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "hidden",
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .DataGallary_description
                                                              .Gallary_description[
                                                              index
                                                            ]["image"],
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm
                                                                  .DataGallary_description
                                                                  .Gallary_description[
                                                                  index
                                                                ],
                                                                "image",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.updateHiddenImage(
                                                                $event,
                                                                index
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: item.video,
                                                            expression:
                                                              "item.video",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "text",
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value: item.video,
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                item,
                                                                "video",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            _vm.showGallaryDescription,
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("label", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.show_media,
                                                              expression:
                                                                "item.show_media",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            value: "image",
                                                          },
                                                          domProps: {
                                                            checked: _vm._q(
                                                              item.show_media,
                                                              "image"
                                                            ),
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.showGallaryDescription,
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                item,
                                                                "show_media",
                                                                "image"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" Image"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("label", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.show_media,
                                                              expression:
                                                                "item.show_media",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            value: "video",
                                                          },
                                                          domProps: {
                                                            checked: _vm._q(
                                                              item.show_media,
                                                              "video"
                                                            ),
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.showGallaryDescription,
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                item,
                                                                "show_media",
                                                                "video"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" Video"),
                                                      ]),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("label", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.media_align,
                                                              expression:
                                                                "item.media_align",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            value: "left",
                                                          },
                                                          domProps: {
                                                            checked: _vm._q(
                                                              item.media_align,
                                                              "left"
                                                            ),
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.showGallaryDescription,
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                item,
                                                                "media_align",
                                                                "left"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" Left"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("label", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.media_align,
                                                              expression:
                                                                "item.media_align",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            value: "right",
                                                          },
                                                          domProps: {
                                                            checked: _vm._q(
                                                              item.media_align,
                                                              "right"
                                                            ),
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.showGallaryDescription,
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                item,
                                                                "media_align",
                                                                "right"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" Right"),
                                                      ]),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm
                                                        .DataGallary_description
                                                        .Gallary_description
                                                        .length > 1
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-remove",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.removeRow(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-minus-circle",
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      index ===
                                                      _vm
                                                        .DataGallary_description
                                                        .Gallary_description
                                                        .length -
                                                        1
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-add",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.addRow(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-plus-circle",
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      Number(benefit.full_width_gallary)
                        ? _c(
                            "div",
                            {
                              staticClass: "col-12",
                              staticStyle: { "margin-bottom": "5rem" },
                            },
                            [
                              Number(benefit.full_width_gallary)
                                ? [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          benefit.detail == null
                                            ? "Full Width Gallary"
                                            : benefit.detail[0].name
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "table",
                                        { staticClass: "styled-table" },
                                        [
                                          _vm._m(2, true),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.full_width_gallary,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("quillEditor", {
                                                          on: {
                                                            input:
                                                              _vm.FullWidthGallary,
                                                          },
                                                          model: {
                                                            value: item.title,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "title",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.title",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("quillEditor", {
                                                          on: {
                                                            input:
                                                              _vm.FullWidthGallary,
                                                          },
                                                          model: {
                                                            value:
                                                              item.description,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "description",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.description",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-primary",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toggleImageSelect(
                                                                item.image,
                                                                index,
                                                                "fullwidth"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                       Upload Media\n                   "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.full_width_gallary[
                                                        index
                                                      ]["image"] !== "" &&
                                                      _vm.full_width_gallary[
                                                        index
                                                      ]["image"] !== null
                                                        ? _c("img", {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                            },
                                                            attrs: {
                                                              src: _vm
                                                                .full_width_gallary[
                                                                index
                                                              ]["image"],
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .full_width_gallary[
                                                                index
                                                              ]["image"],
                                                            expression:
                                                              "full_width_gallary[index]['image']",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "hidden",
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .full_width_gallary[
                                                              index
                                                            ]["image"],
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm
                                                                  .full_width_gallary[
                                                                  index
                                                                ],
                                                                "image",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.updateHiddenImage(
                                                                $event,
                                                                index
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value: item.video,
                                                            expression:
                                                              "item.video",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "text",
                                                          required: "",
                                                        },
                                                        domProps: {
                                                          value: item.video,
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                item,
                                                                "video",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            _vm.FullWidthGallary,
                                                          ],
                                                        },
                                                      }),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _c("label", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.show_media,
                                                              expression:
                                                                "item.show_media",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            value: "image",
                                                          },
                                                          domProps: {
                                                            checked: _vm._q(
                                                              item.show_media,
                                                              "image"
                                                            ),
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.FullWidthGallary,
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                item,
                                                                "show_media",
                                                                "image"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" Image"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("label", [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.show_media,
                                                              expression:
                                                                "item.show_media",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            value: "video",
                                                          },
                                                          domProps: {
                                                            checked: _vm._q(
                                                              item.show_media,
                                                              "video"
                                                            ),
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.FullWidthGallary,
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                item,
                                                                "show_media",
                                                                "video"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" Video"),
                                                      ]),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm.full_width_gallary
                                                        .length > 1
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-remove",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.removeRowFullWidth(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-minus-circle",
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      index ===
                                                      _vm.full_width_gallary
                                                        .length -
                                                        1
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-add",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.addRowFullWidth(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-plus-circle",
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.variations, function (variation, index) {
                    return _vm.product_type == "variable"
                      ? [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  variation.detail == null
                                    ? ""
                                    : variation.detail[0].name
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              { staticClass: "form-group mb-3 d-flex" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.variationData[
                                            "variation_" +
                                              variation.attribute_id
                                          ],
                                        expression:
                                          "variationData['variation_' + variation.attribute_id]",
                                      },
                                    ],
                                    staticClass:
                                      "single-select w-100 mb-3 categories-select ms-offscreen",
                                    attrs: { multiple: "" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.variationData,
                                            "variation_" +
                                              variation.attribute_id,
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.setVariations(
                                            "variation_" +
                                              variation.attribute_id
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "",
                                          selected: "",
                                          disabled: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            Select\n                                            " +
                                            _vm._s(
                                              variation.detail == null
                                                ? ""
                                                : variation.detail[0].name
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      variation.variations,
                                      function (variationDetail) {
                                        return _c(
                                          "option",
                                          {
                                            attrs: {
                                              set: (_vm.allVariations[
                                                variationDetail.id
                                              ] =
                                                variationDetail.detail == null
                                                  ? ""
                                                  : variationDetail.detail[0]
                                                      .name),
                                            },
                                            domProps: {
                                              value: variationDetail.id,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  variationDetail.detail == null
                                                    ? ""
                                                    : variationDetail.detail[0]
                                                        .name
                                                ) +
                                                "\n                                        "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }),
                        ]
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm._l(
                    _vm.combinationDetail,
                    function (combination_detail, index) {
                      return _vm.product_type == "variable"
                        ? [
                            index == 0
                              ? [
                                  _c("div", { staticClass: "col-md-3" }, [
                                    _vm._v("Variant"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-3" }, [
                                    _vm._v(" Price"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-3" }, [
                                    _vm._v("SKU"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-3" }, [
                                    _vm._v("Image"),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-3 mt-3" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(combination_detail.variation_name) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-3 mt-3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.combinationPrice[
                                        combination_detail.price
                                      ],
                                    expression:
                                      "combinationPrice[combination_detail.price]",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  name: combination_detail.price,
                                },
                                domProps: {
                                  value:
                                    _vm.combinationPrice[
                                      combination_detail.price
                                    ],
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.combinationPrice,
                                        combination_detail.price,
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.setCombinationPrice(
                                        combination_detail.price,
                                        _vm.combinationPrice[
                                          combination_detail.price
                                        ]
                                      )
                                    },
                                  ],
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-3 mt-3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.combinationSku[
                                        combination_detail.sku
                                      ],
                                    expression:
                                      "combinationSku[combination_detail.sku]",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  name: combination_detail.sku,
                                },
                                domProps: {
                                  value:
                                    _vm.combinationSku[combination_detail.sku],
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.combinationSku,
                                        combination_detail.sku,
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.setCombinationSku(
                                        combination_detail.sku,
                                        $event.target.value
                                      )
                                    },
                                  ],
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-3 mt-3" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleImageSelect(
                                        combination_detail.gallary
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Upload Media\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.combinationGalleryPath[
                                combination_detail.gallary
                              ] != "" &&
                              _vm.combinationGalleryPath[
                                combination_detail.gallary
                              ] != null
                                ? _c("img", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      src: _vm.combinationGalleryPath[
                                        combination_detail.gallary
                                      ],
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        : _vm._e()
                    }
                  ),
                  _vm._v(" "),
                  [
                    _c("div", { staticClass: "container" }, [
                      _c(
                        "ul",
                        { staticClass: "nav nav-tabs" },
                        _vm._l(
                          _vm.fusionDetail,
                          function (fusion_detail, index) {
                            return _c(
                              "li",
                              { key: index, staticClass: "nav-item" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    class: {
                                      active: _vm.activeTab === fusion_detail,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.activeTab = fusion_detail
                                        _vm.log(
                                          _vm.fusionsData,
                                          _vm.fusionDetail
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(fusion_detail.benefit_name))]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _vm.fusionDetail
                        ? _c(
                            "div",
                            { staticClass: "tab-content" },
                            _vm._l(
                              _vm.fusionDetail,
                              function (fusion_detail, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "tab-pane",
                                    class: {
                                      active: _vm.activeTab === fusion_detail,
                                    },
                                  },
                                  [
                                    _vm.activeTab === fusion_detail ||
                                    _vm.log(_vm.activeTab, fusion_detail)
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "card-body" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "container" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-6",
                                                        },
                                                        [
                                                          _c(
                                                            "h4",
                                                            {
                                                              staticClass:
                                                                "nav nav-pills lang-tab",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  fusion_detail.benefit_name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-3",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Product Score "
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "fieldset",
                                                            {
                                                              staticClass:
                                                                "form-group mb-3",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .fusionsData[
                                                                        index
                                                                      ][
                                                                        "score"
                                                                      ],
                                                                    expression:
                                                                      "fusionsData[index]['score']",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control round bg-transparent text-dark",
                                                                attrs: {
                                                                  type: "number",
                                                                  name:
                                                                    "fusion_detail.score_" +
                                                                    index,
                                                                  placeholder:
                                                                    "Score",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .fusionsData[
                                                                      index
                                                                    ]["score"],
                                                                },
                                                                on: {
                                                                  input: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        _vm
                                                                          .fusionsData[
                                                                          index
                                                                        ],
                                                                        "score",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setFusion(
                                                                        _vm
                                                                          .fusionsData[
                                                                          index
                                                                        ][
                                                                          "score"
                                                                        ]
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.errors.has(
                                                            "fusion.score_" +
                                                              index
                                                          )
                                                            ? _c("small", {
                                                                staticClass:
                                                                  "form-text text-danger",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.errors.get(
                                                                        "fusion.score_" +
                                                                          index
                                                                      )
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-3",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "Product Note "
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "fieldset",
                                                            {
                                                              staticClass:
                                                                "form-group mb-3",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .fusionsData[
                                                                        index
                                                                      ]["note"],
                                                                    expression:
                                                                      "fusionsData[index]['note']",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control round bg-transparent text-dark",
                                                                attrs: {
                                                                  type: "text",
                                                                  name:
                                                                    "fusion.note_" +
                                                                    index,
                                                                  placeholder:
                                                                    "Note",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .fusionsData[
                                                                      index
                                                                    ]["note"],
                                                                },
                                                                on: {
                                                                  input: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        _vm
                                                                          .fusionsData[
                                                                          index
                                                                        ],
                                                                        "note",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setFusion(
                                                                        _vm
                                                                          .fusionsData[
                                                                          index
                                                                        ][
                                                                          "note"
                                                                        ]
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.errors.has(
                                                            "fusion.note_" +
                                                              index
                                                          )
                                                            ? _c("small", {
                                                                staticClass:
                                                                  "form-text text-danger",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.errors.get(
                                                                        "fusion.note_" +
                                                                          index
                                                                      )
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "h6",
                                                        {
                                                          staticClass:
                                                            "text-body",
                                                        },
                                                        [_vm._v("Description")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-12",
                                                        },
                                                        [
                                                          _c("textarea", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .fusionsData[
                                                                    index
                                                                  ][
                                                                    "description"
                                                                  ],
                                                                expression:
                                                                  "fusionsData[index]['description']",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              height: "70px",
                                                            },
                                                            attrs: {
                                                              id: "label-textarea",
                                                              name:
                                                                "fusion.description_" +
                                                                index,
                                                              rows: "6",
                                                              placeholder:
                                                                "Overview",
                                                              spellcheck:
                                                                "false",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.fusionsData[
                                                                  index
                                                                ][
                                                                  "description"
                                                                ],
                                                            },
                                                            on: {
                                                              input: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  )
                                                                    return
                                                                  _vm.$set(
                                                                    _vm
                                                                      .fusionsData[
                                                                      index
                                                                    ],
                                                                    "description",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.setFusion(
                                                                    _vm
                                                                      .fusionsData[
                                                                      index
                                                                    ][
                                                                      "description"
                                                                    ]
                                                                  )
                                                                },
                                                              ],
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _vm.errors.has(
                                                            "fusion.description_" +
                                                              index
                                                          )
                                                            ? _c("small", {
                                                                staticClass:
                                                                  "form-text text-danger",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.errors.get(
                                                                        "fusion.description_" +
                                                                          index
                                                                      )
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.errors.has(
                                                            "fusion.description_" +
                                                              index
                                                          )
                                                            ? _c("small", {
                                                                staticClass:
                                                                  "form-text text-danger",
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      _vm.errors.get(
                                                                        "fusion.description_" +
                                                                          index
                                                                      )
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-6",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass: "row",
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c("hr"),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-md-6 mt-6",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-primary",
                                                                          attrs:
                                                                            {
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.toggleImageSelect(
                                                                                  "fusion.gallary_" +
                                                                                    index,
                                                                                  index,
                                                                                  "fusion"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                            Upload Media\n                                                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm
                                                                        .fusionsGalleryPath[
                                                                        "fusion.gallary_" +
                                                                          index
                                                                      ] != "" &&
                                                                      _vm
                                                                        .fusionsGalleryPath[
                                                                        "fusion.gallary_" +
                                                                          index
                                                                      ] != null
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "300px",
                                                                                  height:
                                                                                    "400px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  src: _vm
                                                                                    .fusionsGalleryPath[
                                                                                    "fusion.gallary_" +
                                                                                      index
                                                                                  ],
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("hr"),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12",
                                                                },
                                                                [
                                                                  _c("hr"),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-md-6 mt-6",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-primary",
                                                                          attrs:
                                                                            {
                                                                              type: "button",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.toggleIconSelect(
                                                                                  "fusion.icon_" +
                                                                                    index,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                            Upload icon\n                                                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm
                                                                        .fusionsGalleryPath[
                                                                        "fusion.icon_" +
                                                                          index
                                                                      ] != "" &&
                                                                      _vm
                                                                        .fusionsGalleryPath[
                                                                        "fusion.icon_" +
                                                                          index
                                                                      ] != null
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "300px",
                                                                                  height:
                                                                                    "400px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  src: _vm
                                                                                    .fusionsGalleryPath[
                                                                                    "fusion.icon_" +
                                                                                      index
                                                                                  ],
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("hr"),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-6",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-12",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Product Title1 "
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "fieldset",
                                                                {
                                                                  staticClass:
                                                                    "form-group mb-3",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .fusionsData[
                                                                              index
                                                                            ][
                                                                              "heading1"
                                                                            ],
                                                                          expression:
                                                                            "fusionsData[index]['heading1']",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control round bg-transparent text-dark",
                                                                    attrs: {
                                                                      type: "text",
                                                                      name:
                                                                        "fusion_detail.heading1_" +
                                                                        index,
                                                                      placeholder:
                                                                        "product_score",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm
                                                                          .fusionsData[
                                                                          index
                                                                        ][
                                                                          "heading1"
                                                                        ],
                                                                    },
                                                                    on: {
                                                                      input: [
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            _vm
                                                                              .fusionsData[
                                                                              index
                                                                            ],
                                                                            "heading1",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.setFusion(
                                                                            _vm
                                                                              .fusionsData[
                                                                              index
                                                                            ][
                                                                              "heading1"
                                                                            ]
                                                                          )
                                                                        },
                                                                      ],
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.errors.has(
                                                                "fusion.heading1_" +
                                                                  index
                                                              )
                                                                ? _c("small", {
                                                                    staticClass:
                                                                      "form-text text-danger",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.errors.get(
                                                                            "fusion.heading1_" +
                                                                              index
                                                                          )
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-12",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Product Title2 "
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "fieldset",
                                                                {
                                                                  staticClass:
                                                                    "form-group mb-3",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .fusionsData[
                                                                              index
                                                                            ][
                                                                              "heading2"
                                                                            ],
                                                                          expression:
                                                                            "fusionsData[index]['heading2']",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control round bg-transparent text-dark",
                                                                    attrs: {
                                                                      type: "text",
                                                                      name:
                                                                        "fusion.heading2_" +
                                                                        index,
                                                                      placeholder:
                                                                        "product_score",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm
                                                                          .fusionsData[
                                                                          index
                                                                        ][
                                                                          "heading2"
                                                                        ],
                                                                    },
                                                                    on: {
                                                                      input: [
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            _vm
                                                                              .fusionsData[
                                                                              index
                                                                            ],
                                                                            "heading2",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.setFusion(
                                                                            _vm
                                                                              .fusionsData[
                                                                              index
                                                                            ][
                                                                              "heading2"
                                                                            ]
                                                                          )
                                                                        },
                                                                      ],
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.errors.has(
                                                                "fusion.heading2_" +
                                                                  index
                                                              )
                                                                ? _c("small", {
                                                                    staticClass:
                                                                      "form-text text-danger",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.errors.get(
                                                                            "fusion.heading2_" +
                                                                              index
                                                                          )
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "text-body",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                                Content\n                                                            "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "fieldset",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-12",
                                                            },
                                                            [
                                                              _c("textarea", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .fusionsData[
                                                                        index
                                                                      ]["body"],
                                                                    expression:
                                                                      "fusionsData[index]['body']",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "form-control",
                                                                staticStyle: {
                                                                  height:
                                                                    "100px",
                                                                },
                                                                attrs: {
                                                                  id: "label-textarea",
                                                                  name:
                                                                    "fusion_detail.body_" +
                                                                    index,
                                                                  rows: "6",
                                                                  placeholder:
                                                                    "Overview",
                                                                  spellcheck:
                                                                    "false",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .fusionsData[
                                                                      index
                                                                    ]["body"],
                                                                },
                                                                on: {
                                                                  input: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        _vm
                                                                          .fusionsData[
                                                                          index
                                                                        ],
                                                                        "body",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.setFusion(
                                                                        _vm
                                                                          .fusionsData[
                                                                          index
                                                                        ][
                                                                          "body"
                                                                        ]
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _vm.errors.has(
                                                                "fusion.body_" +
                                                                  index
                                                              )
                                                                ? _c("small", {
                                                                    staticClass:
                                                                      "form-text text-danger",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.errors.get(
                                                                            "fusion.body_" +
                                                                              index
                                                                          )
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.errors.has(
                                                                "fusion.body_" +
                                                                  index
                                                              )
                                                                ? _c("small", {
                                                                    staticClass:
                                                                      "form-text text-danger",
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          _vm.errors.get(
                                                                            "fusion.body_" +
                                                                              index
                                                                          )
                                                                        ),
                                                                    },
                                                                  })
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ],
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-dark swipe-to-top cta",
              class: { active: _vm.isActive("info-tab") },
              attrs: { "data-toggle": "pill" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setActive("info-tab")
                },
              },
            },
            [_vm._v("Back")]
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary cta",
              class: { active: _vm.isActive("seo-tab") },
              attrs: { "data-toggle": "pill", href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setActive("seo-tab")
                },
              },
            },
            [_vm._v("Continue")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal },
        on: {
          toggleImageSelect: _vm.toggleImageSelect,
          setImage: _vm.setImage,
        },
      }),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showIconModal },
        on: { toggleImageSelect: _vm.toggleIconSelect, setImage: _vm.setIcon },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [
            _vm._v(
              "\n                    Advance Information\n                "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Title")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Image")]),
        _vm._v(" "),
        _c("th", [_vm._v("Video")]),
        _vm._v(" "),
        _c("th", [_vm._v("Show Media")]),
        _vm._v(" "),
        _c("th", [_vm._v("Align Media")]),
        _vm._v(" "),
        _c("th", [_vm._v("Action")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Title")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Image")]),
        _vm._v(" "),
        _c("th", [_vm._v("Video")]),
        _vm._v(" "),
        _c("th", [_vm._v("Show Media")]),
        _vm._v(" "),
        _c("th", [_vm._v("Action")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }