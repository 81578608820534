var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header-mobile align-items-center header-mobile-fixed",
      attrs: { id: "tc_header_mobile" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm.$parent.permissions.includes("pos")
          ? _c("div", { staticClass: "posicon d-lg-block" }, [
              _c(
                "a",
                {
                  staticClass:
                    "btn btn-primary d-flex align-items-center justify-content-center white mr-2",
                  attrs: { href: "/admin/pos" },
                },
                [_vm._v("POS")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn p-0",
            attrs: { id: "tc_aside_mobile_toggle" },
            on: {
              click: function ($event) {
                return _vm.setBurgerMenu()
              },
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "bi bi-justify-right",
                attrs: {
                  width: "20px",
                  height: "20px",
                  viewBox: "0 0 16 16",
                  fill: "currentColor",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d: "M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "posicon d-lg-block" }, [
          _c(
            "div",
            { staticClass: "dropdown", class: { show: _vm.showdropdown } },
            [
              _c(
                "div",
                {
                  staticClass: "topbar-item",
                  attrs: {
                    "data-toggle": "dropdown",
                    "data-display": "static",
                    "aria-expanded": _vm.showdropdown,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showDropDown()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-dark-50 font-size-base d-none d-xl-inline mr-3",
                        },
                        [
                          _vm._v(
                            "\n                                            " +
                              _vm._s(_vm.username)
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "symbol symbol-35 symbol-light-success",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "symbol-label font-size-h5" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "bi bi-person-fill",
                                  attrs: {
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 16 16",
                                    fill: "currentColor",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      d: "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu dropdown-menu-right",
                  class: { show: _vm.showdropdown },
                  staticStyle: { "min-width": "150px" },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: "/admin/profile" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "svg-icon svg-icon-xl svg-icon-primary mr-2",
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "feather feather-user",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "20px",
                                height: "20px",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                stroke: "currentColor",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
                                },
                              }),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: { cx: "12", cy: "7", r: "4" },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t\tEdit Profile\n\t\t\t\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.logOut()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "svg-icon svg-icon-xl svg-icon-primary mr-2",
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "feather feather-power",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "20px",
                                height: "20px",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                stroke: "currentColor",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            },
                            [
                              _c("path", {
                                attrs: { d: "M18.36 6.64a9 9 0 1 1-12.73 0" },
                              }),
                              _vm._v(" "),
                              _c("line", {
                                attrs: {
                                  x1: "12",
                                  y1: "2",
                                  x2: "12",
                                  y2: "12",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t\tLogout\n\t\t\t\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "brand-logo", attrs: { href: "/admin/dashboard" } },
      [
        _c("span", { staticClass: "brand-text" }, [
          _c("img", {
            staticStyle: { height: "25px" },
            attrs: { alt: "Logo", src: "/images/logo.png" },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }