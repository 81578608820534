import { render, staticRenderFns } from "./AddProduct.vue?vue&type=template&id=1453af39"
import script from "./AddProduct.vue?vue&type=script&lang=js"
export * from "./AddProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/lampp/htdocs/projects/Mattressforless/mattressforlesssTest/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1453af39')) {
      api.createRecord('1453af39', component.options)
    } else {
      api.reload('1453af39', component.options)
    }
    module.hot.accept("./AddProduct.vue?vue&type=template&id=1453af39", function () {
      api.rerender('1453af39', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/AddProduct.vue"
export default component.exports