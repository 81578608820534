var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group row" },
    [
      _c("div", { staticClass: "col-md-6" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("fieldset", { staticClass: "form-group mb-3" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.webgeneral.general_announcements_description,
                expression: "webgeneral.general_announcements_description",
              },
            ],
            staticClass: "form-control border-dark",
            attrs: { type: "text", cols: "12", rows: "12" },
            domProps: {
              value: _vm.webgeneral.general_announcements_description,
            },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.webgeneral,
                  "general_announcements_description",
                  $event.target.value
                )
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("fieldset", { staticClass: "form-group mb-3" }, [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.toggleImageSelect()
                  },
                },
              },
              [_vm._v("Select Announcement Image gallary")]
            ),
            _vm._v(" "),
            _c(
              "small",
              {
                staticClass: "form-text text-muted",
                attrs: { id: "textHelp" },
              },
              [_vm._v("Select Announcement Image gallary.")]
            ),
            _vm._v(" "),
            _vm.errors.has("gallary_id")
              ? _c("small", {
                  staticClass: "form-text text-danger",
                  domProps: {
                    textContent: _vm._s(_vm.errors.get("gallary_id")),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.webgeneral.general_announcements_image != ""
              ? _c("img", {
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: { src: _vm.webgeneral.general_announcements_image },
                })
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "list-unstyled mb-0 login-forms" }, [
          _c("li", { staticClass: "mr-2 mb-1" }, [
            _c("fieldset", [
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "checkbox",
                    name: "general_announcements_active",
                    id: "general_announcements_active",
                  },
                  domProps: {
                    checked:
                      _vm.webgeneral.general_announcements_active == "1"
                        ? "checked"
                        : "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.check($event)
                    },
                  },
                }),
                _vm._m(2),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("br"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                return _vm.updateSetting()
              },
            },
          },
          [_vm._v("Submit")]
        ),
      ]),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal },
        on: {
          toggleImageSelect: _vm.toggleImageSelect,
          setImage: _vm.setImage,
        },
      }),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModalIcon },
        on: {
          toggleImageSelect: _vm.toggleImageSelectIcon,
          setImage: _vm.setIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("h4", [
        _vm._v(
          "\n                        Announcement Description\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("h4", [
        _vm._v(
          "\n                        Announcement Image\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "custom-control-label",
        attrs: { for: "general_announcements_active" },
      },
      [
        _c("h4", [
          _vm._v(
            "\n                        Announcement Active\n                    "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }