import { render, staticRenderFns } from "./DigitalClock.vue?vue&type=template&id=46443eb2&scoped=true"
import script from "./DigitalClock.vue?vue&type=script&lang=js"
export * from "./DigitalClock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46443eb2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/lampp/htdocs/projects/Mattressforless/mattressforlesssTest/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('46443eb2')) {
      api.createRecord('46443eb2', component.options)
    } else {
      api.reload('46443eb2', component.options)
    }
    module.hot.accept("./DigitalClock.vue?vue&type=template&id=46443eb2&scoped=true", function () {
      api.rerender('46443eb2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/settings/DigitalClock.vue"
export default component.exports