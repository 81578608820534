var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group row" },
    [
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Modal Footer Logo")]),
        _vm._v(" "),
        _c("fieldset", { staticClass: "form-group mb-3" }, [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.toggleImageSelect()
                  },
                },
              },
              [_vm._v("Select Logo from gallary")]
            ),
            _vm._v(" "),
            _c(
              "small",
              {
                staticClass: "form-text text-muted",
                attrs: { id: "textHelp" },
              },
              [_vm._v("Select Logo from gallary.")]
            ),
            _vm._v(" "),
            _vm.errors.has("gallary_id")
              ? _c("small", {
                  staticClass: "form-text text-danger",
                  domProps: {
                    textContent: _vm._s(_vm.errors.get("gallary_id")),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.webgeneral.modal_footer_image != ""
              ? _c("img", {
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: { src: _vm.webgeneral.modal_footer_image },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("br"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                return _vm.updateSetting()
              },
            },
          },
          [_vm._v("Submit")]
        ),
      ]),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal },
        on: {
          toggleImageSelect: _vm.toggleImageSelect,
          setImage: _vm.setImage,
        },
      }),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModalIcon },
        on: {
          toggleImageSelect: _vm.toggleImageSelectIcon,
          setImage: _vm.setIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }