<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="
                    card card-custom
                    gutter-b
                    bg-transparent
                    shadow-none
                    border-0
                  ">
                                    <div class="
                      card-header
                      align-items-center
                      border-bottom-dark
                      px-0
                    ">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Home info setting
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div>
                                            <form id="myform">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="form-group col-md-4">
                                                                <button type="button" class="btn btn-primary"
                                                                    @click="toggleImageSelect1()">
                                                                    Upload Media
                                                                </button>
                                                                <div class="clearfix"></div>
                                                                <small id="textHelp" class="form-text text-muted"
                                                                    v-if="gallary_path_1 == null || gallary_path_1 == ''">Select
                                                                    Image file from gallary.</small>
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('gallary_id')"
                                                                    v-text="errors.get('gallary_id')"></small>

                                                                <img v-if="gallary_path_1 != ''" :src="gallary_path_1"
                                                                    style="width: 100px; height: 100px" />
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label class="text-dark">Title</label>
                                                                <input type="text" class="form-control" v-model="home_info.home_title_1">
                                                                <small class="form-text text-danger" v-if="errors.has('home_title_1')"
                                                                    v-text="errors.get('home_title_1')"></small>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label class="text-dark">Sub Title</label>
                                                                <input type="text" class="form-control" v-model="home_info.home_sub_title_1">
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('home_sub_title_1')"
                                                                    v-text="errors.get('home_sub_title_1')"></small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="form-group col-md-4">
                                                                <button type="button" class="btn btn-primary"
                                                                    @click="toggleImageSelect2()">
                                                                    Upload Media
                                                                </button>
                                                                <div class="clearfix"></div>
                                                                <small id="textHelp" class="form-text text-muted"
                                                                    v-if="gallary_path_2 == null || gallary_path_2 == ''">Select
                                                                    Image file from gallary.</small>
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('gallary_id')"
                                                                    v-text="errors.get('gallary_id')"></small>

                                                                <img v-if="gallary_path_2 != ''" :src="gallary_path_2"
                                                                    style="width: 100px; height: 100px" />
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label class="text-dark">Title</label>
                                                                <input type="text" class="form-control" v-model="home_info.home_title_2">
                                                                <small class="form-text text-danger" v-if="errors.has('home_title_2')"
                                                                    v-text="errors.get('home_title_2')"></small>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label class="text-dark">Sub Title</label>
                                                                <input type="text" class="form-control" v-model="home_info.home_sub_title_2">
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('home_sub_title_2')"
                                                                    v-text="errors.get('home_sub_title_2')"></small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="form-group col-md-4">
                                                                <button type="button" class="btn btn-primary"
                                                                    @click="toggleImageSelect3()">
                                                                    Upload Media
                                                                </button>
                                                                <div class="clearfix"></div>
                                                                <small id="textHelp" class="form-text text-muted"
                                                                    v-if="gallary_path_3 == null || gallary_path_3 == ''">Select
                                                                    Image file from gallary.</small>
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('gallary_id')"
                                                                    v-text="errors.get('gallary_id')"></small>

                                                                <img v-if="gallary_path_3 != ''" :src="gallary_path_3"
                                                                    style="width: 100px; height: 100px" />
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label class="text-dark">Title</label>
                                                                <input type="text" class="form-control" v-model="home_info.home_title_3">
                                                                <small class="form-text text-danger" v-if="errors.has('home_title_3')"
                                                                    v-text="errors.get('home_title_3')"></small>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label class="text-dark">Sub Title</label>
                                                                <input type="text" class="form-control" v-model="home_info.home_sub_title_3">
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('home_sub_title_3')"
                                                                    v-text="errors.get('home_sub_title_3')"></small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="form-group col-md-4">
                                                                <button type="button" class="btn btn-primary"
                                                                    @click="toggleImageSelect4()">
                                                                    Upload Media
                                                                </button>
                                                                <div class="clearfix"></div>
                                                                <small id="textHelp" class="form-text text-muted"
                                                                    v-if="gallary_path_4 == null || gallary_path_4 == ''">Select
                                                                    Image file from gallary.</small>
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('gallary_id')"
                                                                    v-text="errors.get('gallary_id')"></small>

                                                                <img v-if="gallary_path_4 != ''" :src="gallary_path_4"
                                                                    style="width: 100px; height: 100px" />
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label class="text-dark">Title</label>
                                                                <input type="text" class="form-control" v-model="home_info.home_title_4">
                                                                <small class="form-text text-danger" v-if="errors.has('home_title_4')"
                                                                    v-text="errors.get('home_title_4')"></small>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label class="text-dark">Sub Title</label>
                                                                <input type="text" class="form-control" v-model="home_info.home_sub_title_4">
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('home_sub_title_4')"
                                                                    v-text="errors.get('home_sub_title_4')"></small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" @click="addUpdateHome()" class="btn btn-primary"
                                                    style="margin-top: 85px">
                                                    Submit
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <attach-image @toggleImageSelect="toggleImageSelect1" :showModal="showModal1" @setImage="setImage1" />
        <attach-image @toggleImageSelect="toggleImageSelect2" :showModal="showModal2" @setImage="setImage2" />
        <attach-image @toggleImageSelect="toggleImageSelect3" :showModal="showModal3" @setImage="setImage3" />
        <attach-image @toggleImageSelect="toggleImageSelect4" :showModal="showModal4" @setImage="setImage4" />
    </div>
</template>

<script>
import ErrorHandling from "../../ErrorHandling";
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueEditor
    },
    data() {
        return {
            display_form: 0,
            home_info: {
                home_title_1: '',
                home_title_2: '',
                home_title_3: '',
                home_title_4: '',
                home_sub_title_1: '',
                home_sub_title_2: '',
                home_sub_title_3: '',
                home_sub_title_4: '',
                home_gallary_id_1: '',
                home_gallary_id_2: '',
                home_gallary_id_3: '',
                home_gallary_id_4: '',
            },
            top_offer_text: "",
            showModal1: false,
            showModal2: false,
            showModal3: false,
            showModal4: false,
            searchParameter: "",
            error_message: "",
            edit: false,
            actions: false,
            pagination: {},
            request_method: "",
            token: [],
            gallary_path_1: "",
            gallary_path_2: "",
            gallary_path_3: "",
            gallary_path_4: "",
            errors: new ErrorHandling(),
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
        };
    },

    methods: {
        fetchHome(page_url) {
            this.$parent.loading = true;
            let vm = this;
            page_url = page_url || "/api/admin/home-info-setting";

            axios
                .get(page_url, this.token)
                .then((res) => {
                    console.log(res.data.data);
                    this.home_info = res.data.data;
                    this.gallary_path_1 = res.data.data.home_gallary_id_1.gallary_detail[0].path;
                    this.gallary_path_2 = res.data.data.home_gallary_id_2.gallary_detail[0].path;
                    this.gallary_path_3 = res.data.data.home_gallary_id_3.gallary_detail[0].path;
                    this.gallary_path_4 = res.data.data.home_gallary_id_4.gallary_detail[0].path;
                })
                .finally(() => (this.$parent.loading = false));
        },
        toggleImageSelect1() {
            this.showModal1 = !this.showModal1;
        },
        toggleImageSelect2() {
            this.showModal2 = !this.showModal2;
        },
        toggleImageSelect3() {
            this.showModal3 = !this.showModal3;
        },
        toggleImageSelect4() {
            this.showModal4 = !this.showModal4;
        },
        setImage1(gallary) {
            (this.gallary_path_1 = gallary.gallary_path),
            (this.home_info.home_gallary_id_1 = gallary.gallary_id);
        },
        setImage2(gallary) {
            (this.gallary_path_2 = gallary.gallary_path),
            (this.home_info.home_gallary_id_2 = gallary.gallary_id);
        },
        setImage3(gallary) {
            (this.gallary_path_3 = gallary.gallary_path),
            (this.home_info.home_gallary_id_3 = gallary.gallary_id);
        },
        setImage4(gallary) {
            (this.gallary_path_4 = gallary.gallary_path),
            (this.home_info.home_gallary_id_4 = gallary.gallary_id);
        },


        addUpdateHome() {
            this.$parent.loading = true;
            var url = "/api/admin/home-info-setting";

            axios.post(url, this.home_info, this.token)
                .then((res) => {
                    if (res.data.status) {
                        this.display_form = 0;
                        this.$toaster.success("Settings has been updated successfully");
                        this.clearForm();
                        this.fetchHome();
                    } else {
                        this.$toaster.error(res.data.message);
                    }
                })
                .catch((error) => {
                    this.error_message = "";
                    this.errors = new ErrorHandling();
                    if (error.response.status == 422) {
                        if (error.response.data.status == "Error") {
                            this.error_message = error.response.data.message;
                        } else {
                            this.errors.record(error.response.data.errors);
                        }
                    }
                })
                .finally(() => (this.$parent.loading = false));
        },

        clearForm() {
            this.edit = false;
            this.display_form = 0;
            this.offers.id = null;
            this.offers.top_offer_text = "";
            this.top_offer_text = "";
        },
    },
    mounted() {
        var token = localStorage.getItem("token");
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        this.fetchHome();
    },
};
</script>
