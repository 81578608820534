<template>
  <div>
      <div class="d-flex flex-column-fluid">
          <!--begin::Container-->
          <div class="container-fluid">
              <div class="row">
                  <div class="col-12">
                      <div class="row">
                          <div class="col-lg-12 col-xl-12">
                              <div class="
                    card card-custom
                    gutter-b
                    bg-transparent
                    shadow-none
                    border-0
                  ">
                                  <div class="
                      card-header
                      align-items-center
                      border-bottom-dark
                      px-0
                    ">
                                      <div class="card-title mb-0">
                                          <h3 class="card-label mb-0 font-weight-bold text-body">
                                              Product List
                                          </h3>
                                      </div>
                                      <div class="icons d-flex">
                                          <router-link to="/admin/add-product" class="btn ml-2 p-0 kt_notes_panel_toggle"
                                              data-toggle="tooltip" title="" data-placement="right"
                                              data-original-title="Check out more demos"
                                              v-if="$parent.permissions.includes('product-manage')">
                                              <span class="
                            bg-secondary
                            h-30px
                            font-size-h5
                            w-30px
                            d-flex
                            align-items-center
                            justify-content-center
                            rounded-circle
                            shadow-sm
                          ">
                                                  <svg width="25px" height="25px" viewBox="0 0 16 16"
                                                      class="bi bi-plus white" fill="currentColor"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd"
                                                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z">
                                                      </path>
                                                  </svg>
                                              </span>
                                          </router-link>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12">
                              <div class="card card-custom gutter-b bg-white border-0">
                                  <div class="card-body">
                                      <div>
                                          <div class="table-responsive" id="printableTable">
                                              <div id="product_wrapper" class="dataTables_wrapper no-footer">
                                                  <div class="dataTables_length" id="product_length">
                                                      <label>Show
                                                          <select name="product_length" class="" v-model="limit"
                                                              v-on:change="fetchproducts()">
                                                              <option value="10">10</option>
                                                              <option value="25">25</option>
                                                              <option value="50">50</option>
                                                              <option value="100">100</option>
                                                              <option value="200">200</option>
                                                              <option value="500">500</option>
                                                              <option value="1000">1000</option>
                                                          </select>
                                                          entries</label>
                                                  </div>

                                                  <div id="product_filter" class="dataTables_filter">
                                                      <label>Search:<input type="text" class="" placeholder=""
                                                              v-model="searchParameter"
                                                              @keyup="fetchproducts()" /></label>
                                                      <button style="padding: 0.375rem 0.75rem !important;"
                                                          class="btn btn-light  font-weight-bold mr-1 mb-1"
                                                          v-if="this.searchParameter != ''" @click="clearSearch"> Clear
                                                      </button>

                                                  </div>
                                                  <table id="product" class="display dataTable no-footer" product="grid">
                                                      <thead class="text-body">
                                                          <tr product="row">
                                                              <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                  aria-sort="ascending"
                                                                  aria-label="ID: activate to sort column descending"
                                                                  style="width: 31.25px" @click="sorting('id')" :class="(this.$data.sortType == 'asc' ||
                                                                          this.$data.sortType == 'ASC') &&
                                                                          this.$data.sortBy == 'id'
                                                                          ? 'sorting_asc'
                                                                          : (this.$data.sortType == 'desc' ||
                                                                              this.$data.sortType == 'DESC') &&
                                                                              this.$data.sortBy == 'id'
                                                                              ? 'sorting_desc'
                                                                              : 'sorting'
                                                                      ">
                                                                  ID
                                                              </th>
                                                              <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                  aria-sort="ascending"
                                                                  aria-label="ID: activate to sort column descending"
                                                                  style="width: 31.25px" @click="sorting('title')" :class="(this.$data.sortType == 'asc' ||
                                                                          this.$data.sortType == 'ASC') &&
                                                                          this.$data.sortBy == 'title'
                                                                          ? 'sorting_asc'
                                                                          : (this.$data.sortType == 'desc' ||
                                                                              this.$data.sortType == 'DESC') &&
                                                                              this.$data.sortBy == 'title'
                                                                              ? 'sorting_desc'
                                                                              : 'sorting'
                                                                      ">
                                                                  Name
                                                              </th>
                                                              <th class="no-sort sorting_disabled" tabindex="0"
                                                                  rowspan="1" colspan="1"
                                                                  aria-label="product: activate to sort column ascending"
                                                                  style="width: 95.5288px">
                                                                  Product Image
                                                              </th>
                                                              <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                  aria-sort="ascending"
                                                                  aria-label="ID: activate to sort column descending"
                                                                  style="width: 31.25px" @click="sorting('category_name')"
                                                                  :class="(this.$data.sortType == 'asc' ||
                                                                          this.$data.sortType == 'ASC') &&
                                                                          this.$data.sortBy == 'category_name'
                                                                          ? 'sorting_asc'
                                                                          : (this.$data.sortType == 'desc' ||
                                                                              this.$data.sortType == 'DESC') &&
                                                                              this.$data.sortBy == 'category_name'
                                                                              ? 'sorting_desc'
                                                                              : 'sorting'
                                                                      ">
                                                                  Category
                                                              </th>
                                                              <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                  aria-sort="ascending"
                                                                  aria-label="ID: activate to sort column descending"
                                                                  style="width: 31.25px" @click="sorting('product_type')"
                                                                  :class="(this.$data.sortType == 'asc' ||
                                                                          this.$data.sortType == 'ASC') &&
                                                                          this.$data.sortBy == 'product_type'
                                                                          ? 'sorting_asc'
                                                                          : (this.$data.sortType == 'desc' ||
                                                                              this.$data.sortType == 'DESC') &&
                                                                              this.$data.sortBy == 'product_type'
                                                                              ? 'sorting_desc'
                                                                              : 'sorting'
                                                                      ">
                                                                  Type
                                                              </th>
                                                              <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                  aria-sort="ascending"
                                                                  aria-label="ID: activate to sort column descending"
                                                                  style="width: 31.25px" @click="sorting('price')" :class="(this.$data.sortType == 'asc' ||
                                                                          this.$data.sortType == 'ASC') &&
                                                                          this.$data.sortBy == 'price'
                                                                          ? 'sorting_asc'
                                                                          : (this.$data.sortType == 'desc' ||
                                                                              this.$data.sortType == 'DESC') &&
                                                                              this.$data.sortBy == 'price'
                                                                              ? 'sorting_desc'
                                                                              : 'sorting'
                                                                      ">
                                                                  Price {{ getCurrencyTitle() }}
                                                              </th>

                                                              <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                  aria-sort="ascending"
                                                                  aria-label="ID: activate to sort column descending"
                                                                  style="width: 31.25px"
                                                                  @click="sorting('discount_price')" :class="(this.$data.sortType == 'asc' ||
                                                                          this.$data.sortType == 'ASC') &&
                                                                          this.$data.sortBy == 'price'
                                                                          ? 'sorting_asc'
                                                                          : (this.$data.sortType == 'desc' ||
                                                                              this.$data.sortType == 'DESC') &&
                                                                              this.$data.sortBy == 'price'
                                                                              ? 'sorting_desc'
                                                                              : 'sorting'
                                                                      ">
                                                                  Discount Price {{ getCurrencyTitle() }}
                                                              </th>
                                                              <th class="sorting" tabindex="0" rowspan="1" colspan="1"
                                                                  aria-sort="ascending"
                                                                  aria-label="ID: activate to sort column descending"
                                                                  style="width: 31.25px"
                                                                  @click="sorting('product_status')" :class="(this.$data.sortType == 'asc' ||
                                                                          this.$data.sortType == 'ASC') &&
                                                                          this.$data.sortBy == 'product_status'
                                                                          ? 'sorting_asc'
                                                                          : (this.$data.sortType == 'desc' ||
                                                                              this.$data.sortType == 'DESC') &&
                                                                              this.$data.sortBy == 'product_status'
                                                                              ? 'sorting_desc'
                                                                              : 'sorting'
                                                                      ">
                                                                  Status
                                                              </th>
                                                              <th v-if="$parent.permissions.includes(
                                                                  'product-manage'
                                                              )
                                                                  " class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action"
                                                                  style="width: 53.1891px">
                                                                  Action
                                                              </th>
                                                          </tr>
                                                      </thead>
                                                      <tbody class="kt-table-tbody text-dark">
                                                          <tr class="kt-table-row kt-table-row-level-0 odd" product="row"
                                                              v-for="product in products" v-bind:key="product.id">
                                                              <td class="sorting_1">
                                                                  {{ product.product_id }}
                                                              </td>
                                                              <td>
                                                                  {{
                                                                      product.detail == null
                                                                      ? ""
                                                                      : product.detail[0]
                                                                          ? product.detail[0].title
                                                                          : ""
                                                                  }}
                                                              </td>
                                                              <td>
                                                                  <img style="height: 80px" :src="product.product_gallary != null
                                                                                  ? '/gallary/' +
                                                                                  product.product_gallary.gallary_name
                                                                                  : ''
                                                                              " />
                                                              </td>
                                                              <td>
                                                                  {{
                                                                      product.category == null
                                                                      ? ""
                                                                      : !product.category[0].category_detail
                                                                          ? ""
                                                                          : product.category[0].category_detail
                                                                              .detail[0]
                                                                              ? product.category[0].category_detail
                                                                                  .detail[0].name
                                                                              : ""
                                                                  }}
                                                              </td>
                                                              <td>
                                                                  {{ product.product_type }}
                                                              </td>
                                                              <td>
                                                                  {{ product.product_price }}
                                                              </td>
                                                              <td>
                                                                  {{ product.product_discount_price }}
                                                              </td>

                                                              <td>
                                                                  {{ product.product_status }}
                                                              </td>

                                                              <td v-if="$parent.permissions.includes(
                                                                  'product-manage'
                                                              )
                                                                  ">
                                                                  
                                                                  <a
                                                                              href="javascript:void(0)"
                                                                              class="click-edit"
                                                                              id="click-edit1"
                                                                              data-toggle="tooltip"
                                                                              title=""
                                                                              data-placement="right"
                                                                              data-original-title="Check out more demos"
                                                                              @click="edit_recommended_product(product)"
                                                                              >
                                                                              <i class="fa fa-award text-warning" style="font-size: 1.5em;"></i>   </a>
                                                                       <a
                                                                              href="javascript:void(0)"
                                                                              class="click-edit"
                                                                              id="click-edit1"
                                                                              data-toggle="tooltip"
                                                                              title=""
                                                                              data-placement="right"
                                                                              data-original-title="Check out more demos"
                                                                              @click="editformavailability(product)"
                                                                              ><i class="fas fa-boxes text-primary" style="font-size: 1.5em;"></i>
                                                                          </a>
                                                                  <router-link :to="{
                                                                      path:
                                                                          '/admin/product/' + product.product_id,
                                                                  }" class="click-edit" id="click-edit1" data-toggle="tooltip" title=""
                                                                      data-placement="right"
                                                                      data-original-title="Check out more demos"
                                                                      @click="editproduct(product)"><i
                                                                          class="fa fa-edit teaxt-success" style="font-size: 1.5em;"></i></router-link>
                                                                  <a class="" href="#"
                                                                      @click="deleteproduct(product.product_id)"><i
                                                                          class="fa fa-trash text-danger" style="font-size: 1.5em;"></i></a>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                                  <ul class="pagination pagination-sm m-0 float-right">
                                                      <li v-bind:class="[
                                                          { disabled: !pagination.prev_page_url },
                                                      ]">
                                                          <a class="page-link" href="#"
                                                              @click="fetchproducts(pagination.prev_page_url)">Previous</a>
                                                      </li>

                                                      <li class="disabled">
                                                          <a class="page-link text-dark" href="#">Page {{
                                                              pagination.current_page }} of
                                                              {{ pagination.last_page }}</a>
                                                      </li>

                                                      <li v-bind:class="[
                                                          { disabled: !pagination.next_page_url },
                                                      ]" class="page-item">
                                                          <a class="page-link" href="#"
                                                              @click="fetchproducts(pagination.next_page_url)">Next</a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      



  <div
    class="offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel"
    v-if="display_form"
    :class="display_form ? 'offcanvas-on' : ''"
  >
    <div
      class="
        offcanvas-header
        d-flex
        align-items-center
        justify-content-between
        pb-3
      "
    >
      <h4 class="font-size-h4 font-weight-bold m-0">Product availability status in each store</h4>
      <a
        href="#"
        class="
          btn btn-sm btn-icon btn-light btn-hover-primary
          kt_notes_panel_close
        "
        v-on:click="clearForm()"
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 16 16"
          class="bi bi-x"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          ></path>
        </svg>
      </a>
    </div>
    <form id="myform">
      <div v-for="(store, index) in storeCount" :key="index">
      <div class="row">

          <h4 class="mt-3 mb-2" style="color: var(--artical-background-primary-dark) !important;">{{product_availability[index].store}}</h4>
      <div class="form-group">
            <label class="text-dark">Description</label>
            <quillEditor   v-model="product_availability[index].description"/>
            <small
              class="form-text text-danger"
              v-if="errors.has('description')"
              v-text="errors.get('description')"
            ></small>
          </div>
      </div>

        <div class="row">
          <div class="form-group" >
            <label class="text-dark">In Store </label>
            <div class="justify-content-between">
              <label class="btn btn-primary p-2 active mr-4">
                <input type="radio"  value="1" id="radio-light" v-model="product_availability[index].instore" >
                In Store
              </label>
              <label class="btn btn-primary p-2">
                <input type="radio"  value="0" id="radio-dark" v-model="product_availability[index].instore">
                                  Online only
              </label>

      </div>            
            <small
              class="form-text text-danger"
              v-if="errors.has(`in_store.${index}`)"
              v-text="errors.get(`in_store.${index}`)"
            ></small>
          </div>
      </div>

      <div class="row">

          <div class="form-group" >
            <label class="text-dark"> Delivery</label>
            <div class="justify-content-between">
              <label class="btn btn-primary p-2 active mr-4">
                <input type="radio"  value="1" id="radio-light" v-model="product_availability[index].delivery" >
                Delivery
              </label>
              <label class="btn btn-primary p-2">
                <input type="radio"  value="0" id="radio-dark" v-model="product_availability[index].delivery">
                                  pickup only
              </label>
      </div>            
            <small
              class="form-text text-danger"
              v-if="errors.has(`delivery.${index}`)"
              v-text="errors.get(`delivery.${index}`)"
            ></small>
          </div>

        </div>
    


        <div class="row">

          <div class="form-group">
            <label class="text-dark">Delivery date is available after how many days from today </label>
            <div class="clearfix"></div>
            <input type="number" class="form-control" v-model="product_availability[index].days_from_today" />

            <small
              class="form-text text-danger"
              v-if="errors.has(`days_from_today.${index}`)"
              v-text="errors.get(`days_from_today.${index}`)"
            ></small>
          </div>
        </div>


        <div class="row">

<div class="form-group">
<label class="text-dark">End of delivery date from the specified date </label>
<div class="clearfix"></div>
<input type="number" class="form-control" v-model="product_availability[index].days_until_end" />

<small
  class="form-text text-danger"
  v-if="errors.has(`days_until_end.${index}`)"
  v-text="errors.get(`days_until_end.${index}`)"
></small>
</div>
</div>
<hr class="mb-2 mt-2">
</div>




        

      <button
        type="button"
        @click="updateProductAvailability()"
        
        class="btn btn-primary"
      >
        Submit
      </button>
    </form>
  </div>

















  <div
    class="offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel"
    v-if="display_form_recommended"
    :class="display_form_recommended ? 'offcanvas-on' : ''"
  >
    <div
      class="
        offcanvas-header
        d-flex
        align-items-center
        justify-content-between
        pb-3
      "
    >
      <h4 class="font-size-h4 font-weight-bold m-0">Recommended Product</h4>
      <a
        href="#"
        class="
          btn btn-sm btn-icon btn-light btn-hover-primary
          kt_notes_panel_close
        "
        v-on:click="clearForm()"
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 16 16"
          class="bi bi-x"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          ></path>
        </svg>
      </a>
    </div>
    <form id="myformRecomended">
      
      <div class="row">
        <div class="col-md-12" style="display: none;">
          <div class="form-group" >
            <div class="justify-content-between">
              <div class="col-md-12">
                               <input type="text" class="form-control" placeholder="Enter category name" v-model="category_name_product_recommended">
                                      <small class="form-text text-danger" v-if="errors.has('recommended_product')" v-text="errors.get('product_id')"></small>
                                  </div>

                                  <small
              class="form-text text-danger"
              v-if="errors.has(`category_product_recommended`)"
              v-text="errors.get(`category_product_recommended`)"
            ></small>
      </div>            
          </div>
      </div>



        <div class="col-md-12">

          <div class="form-group" >
            <div class="justify-content-between">
              <div class="col-md-12">
                                      <fieldset class="form-group mb-3 d-flex">
                                          <multiselect 
                                              v-model="recommended_product"
                                              :options="recommended_products" 
                                              :custom-label="nameWithLang" 
                                              placeholder="Select Product" 
                                              label="name" 
                                              track-by="recommended_product_id"
                                              :multiple="false"
                                              
                                          >
                                          </multiselect>
                                      </fieldset>
                                      <small class="form-text text-danger" v-if="errors.has('recommended_product')" v-text="errors.get('product_id')"></small>
                                  </div>

                                  <small
              class="form-text text-danger"
              v-if="errors.has(`recommended_product`)"
              v-text="errors.get(`recommended_product`)"
            ></small>
      </div>            
          </div>
      </div>




      <div class="col-md-12 mt-4">

          <div class="form-group" >
            <div class="justify-content-between">
              <h6 class="font-size-h4 font-weight-bold m-0" style="color:#264164">Completed Product</h6>

              <div class="col-md-12">
                                      <fieldset class="form-group mb-3 d-flex">
                                          <multiselect 
                                              v-model="completed_product"
                                              :options="completed_products" 
                                              :custom-label="nameWithLang" 
                                              placeholder="Select Product" 
                                              label="name" 
                                              track-by="completed_product_id"
                                              :multiple="false"
                                           



                                              
                                          >
                                          </multiselect>
                                      </fieldset>
                                      <small class="form-text text-danger" v-if="errors.has('completed_product')" v-text="errors.get('product_id')"></small>
                                  </div>

                                  <small
              class="form-text text-danger"
              v-if="errors.has(`completed_product`)"
              v-text="errors.get(`completed_product`)"
            ></small>
      </div>            
          </div>
      </div>






  </div>

     
  <button
        type="button"
        @click="updateProductReset()"
        style="margin-left: 10px;width: 40%;"
        class="btn btn-dark btn-lg align-center"
      >
        Rest Product
      </button>
  



  <button
        type="button"
        @click="updateProductRecommended()"
        style="margin-left: 10px;width: 40%;"
        class="btn btn-primary btn-lg align-center"
      >
        Submit
      </button>
  


        

    </form>
  </div>















 
</div>








</template>

<script>
import ErrorHandling from "./../../ErrorHandling";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Multiselect from "vue-multiselect";

import { quillEditor } from 'vue-quill-editor'
  export default {
  components: {
      quillEditor,
      Multiselect
  },
  data() {
      return {
          display_form: 0,
          display_form_recommended:0,
          products: [],
          searchParameter: "",
          sortBy: "id",
          sortType: "DESC",
          limit: 10,
          error_message: "",
          edit: false,
          test_id:0,
          actions: false,
          pagination: {},
          recommended_products: [],
          completed_products: [],
          request_method: "",
          product_id:"",
          is_default: "0",
          category_name_product_recommended:"",
          editAvailability: false,
          recommendedProduct:false,
          token: [],
          currency: [],
          product_availability: [],
          recommended_product: null,
          completed_product: null,
          storeCount: 0,
          errors: new ErrorHandling(),
          csrf: document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
      };
  },

  methods: {
      fetchRecommendedProducts() {
    this.$parent.loading = true;
    var token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    var responseData = {};

    axios
      .get("/api/admin/product?getAllData=1", config)
      .then((res) => {
        if (res.data.status == "Success") {
          this.recommended_products = res.data.data;
          this.completed_products = res.data.data;
        }
      })
      .finally(() => (this.$parent.loading = false));
  },
  nameWithLang(option) {
    return option.detail ? `${option.detail[0].title}` : "no name";
  },

fetchCurrency() {
          this.$parent.loading = true;
          var token = localStorage.getItem("token");
          const config = {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          };
          var responseData = {};

          axios
              .get("/api/admin/currency?is_default=1", config)
              .then((res) => {
                  if (res.data.status == "Success") {
                      this.currency = res.data.data;
                  }
              })
              .finally(() => (this.$parent.loading = false));
      },
      fetchproducts(page_url) {
          this.$parent.loading = true;
          let vm = this;
          page_url = page_url || "/api/admin/product";
          var arr = page_url.split("?");

          if (arr.length > 1) {
              page_url += "&limit=" + this.limit;
          } else {
              page_url += "?limit=" + this.limit;
          }
          if (this.searchParameter != null) {
              page_url += "&searchParameter=" + this.searchParameter;
          }

          page_url += "&sortBy=" + this.sortBy + "&sortType=" + this.sortType;
          page_url += "&getDetail=1&getCategory=1";

          var responseData = {};

          axios
              .get(page_url, this.token)
              .then((res) => {
                  this.products = res.data.data;
                  console.log(res.data.data);
                  vm.makePagination(res.data.meta, res.data.links);
              })
              .finally(() => (this.$parent.loading = false));
      },
    
      updateProductAvailability() {
    var url = "/api/admin/update_product_availability/" + this.product_id;
    var request_method = "put";

    axios[request_method](url, this.product_availability, this.token)
      .then((res) => {
        if (res.data.status == "Success") {
          this.display_form = 0;
          this.recommendedProduct = 0;
          this.$toaster.success("product has been updated successfully");
          this.clearForm();
          this.fetchSliders();
        } else {
          this.$toaster.error(res.data.message);
        }
      })
      .catch((error) => {
        this.error_message = "";
        this.errors = new ErrorHandling();
        if (error.response.status == 422) {
          if (error.response.data.status == "Error") {
            this.error_message = error.response.data.message;
          } else {
            this.errors.record(error.response.data.errors);
          }
        }
      })
      .finally(() => (this.$parent.loading = false));
  },
  updateProductRecommended() {
    var url = "/api/admin/product_recommendations/" + this.product_id;
    var request_method = "put";
    const payload = {
      recommended_product: this.recommended_product,
      completed_product: this.completed_product,
          
          // Include the selected product ID
      };
    axios[request_method](url, payload, this.token)
      .then((res) => {
        if (res.data.status == "Success") {
          this.display_form = 0;
          this.recommendedProduct = 0;
          this.$toaster.success("product has been updated successfully");
          this.clearForm();
          this.fetchSliders();
        } else {
          this.$toaster.error(res.data.message);
        }
      })
      .catch((error) => {
        this.error_message = "";
        this.errors = new ErrorHandling();
        if (error.response.status == 422) {
          if (error.response.data.status == "Error") {
            this.error_message = error.response.data.message;
          } else {
            this.errors.record(error.response.data.errors);
          }
        }
      })
      .finally(() => (this.$parent.loading = false));
  },
  updateProductReset() {
    var url = "/api/admin/product_recommendations/" + this.product_id;
    var request_method = "put";
    const payload = {
      recommended_product: null,
      completed_product: null,
          
          // Include the selected product ID
      };
    axios[request_method](url, payload, this.token)
      .then((res) => {
        if (res.data.status == "Success") {
          this.display_form = 0;
          this.recommendedProduct = 0;
          this.$toaster.success("product has been updated successfully");
          this.clearForm();
          this.fetchSliders();
        } else {
          this.$toaster.error(res.data.message);
        }
      })
      .catch((error) => {
        this.error_message = "";
        this.errors = new ErrorHandling();
        if (error.response.status == 422) {
          if (error.response.data.status == "Error") {
            this.error_message = error.response.data.message;
          } else {
            this.errors.record(error.response.data.errors);
          }
        }
      })
      .finally(() => (this.$parent.loading = false));
  },

editformavailability(product) {  


    this.editAvailability = true;
    this.display_form = 1;
    this.errors = new ErrorHandling();
    this.product_id = product.product_id;

    var page_url = "/api/admin/get_product_availability/" + product.product_id;

    axios
.get(page_url, this.token)
.then((res) => {
  if (res.data.status == "Success") {

  console.log(res.data.data);
  this.storeCount = res.data.data.length;
  this.product_availability = res.data.data.map((store) => ({
    description: store.availability_desc,
    instore: store.in_store, // Default value for In Store
    delivery: store.delivery, // Default value for Delivery
    days_from_today: store.days_from_today,
    days_until_end: store.days_until_end,
    store_id:store.store_id,
    store:store.store.name,

  }));
}
})
.finally(() => (this.$parent.loading = false));

  
},



edit_recommended_product(product) {  


this.recommendedProduct = true;
this.display_form_recommended = 1;
this.errors = new ErrorHandling();
this.product_id = product.product_id;

var page_url = "/api/admin/product_recommendations/" + product.product_id;

axios
.get(page_url, this.token)
.then((res) => {
if (res.data.status == "Success") {


console.log(res.data.data.recommended_product_id)

const item = this.recommended_products.find(
  (product) => Number(product.product_id) === Number(res.data.data.recommended_product_id)
);

      
      // Set it as the selected value in v-model
      this.recommended_product = item || null;

      console.log(this.recommended_product); // Verify the selected item

}
})
.finally(() => (this.$parent.loading = false));











var page_url = "/api/admin/product_completes/" + product.product_id;

axios
.get(page_url, this.token)
.then((res) => {
if (res.data.status == "Success") {


console.log(res.data.data.completed_product_id)


const item = this.completed_products.find(
  (product) => Number(product.product_id) === Number(res.data.data.completed_product_id)
);

      // Set it as the selected value in v-model
      this.completed_product = item || null;

      console.log(this.completed_product); // Verify the selected item

}
})
.finally(() => (this.$parent.loading = false));









},
addProduct(selectedOption) {
    // If you're manually modifying completed_product here, ensure you're only adding the selectedOption.
    if (!this.completed_product.includes(selectedOption)) {
      this.completed_product.push(selectedOption);
    }
  },
  removeProduct(removedOption) {
    // This will remove the specific item from completed_product.
    this.completed_product = this.completed_product.filter(product => product.id !== removedOption.id);
  },

  clearForm() {
    this.edit = false;
    this.display_form = 0;
    this.display_form_recommended = 0;

  },
      makePagination(meta, links) {
          let pagination = {
              current_page: meta.current_page,
              last_page: meta.last_page,
              next_page_url: links.next,
              prev_page_url: links.prev,
          };

          this.pagination = pagination;
      },
      sorting(sortBy) {
          this.sortBy = sortBy;
          this.sortType =
              this.sortType == "asc" || this.sortType == "ASC"
                  ? (this.sortType = "desc")
                  : (this.sortType = "asc");
          this.fetchproducts();
      },
      getCurrencyTitle() {
          return this.currency == null ? "" : "(" + this.currency.title + ")";
      },
      deleteproduct(id) {
          if (confirm("Are You Sure?")) {
              this.$parent.loading = true;
              axios
                  .delete(`/api/admin/product/${id}`, this.token)
                  .then((res) => {
                      if (res.data.status == "Success") {
                          this.$toaster.success(res.data.message);
                          this.fetchproducts();
                      }
                  })
                  .catch((error) => {
                      if (error.response.status == 422) {
                          if (error.response.data.status == 'Error') {
                              this.$toaster.error(error.response.data.message)
                          }
                      }
                  })
                  .finally(() => (this.$parent.loading = false));
          }
      },
      clearSearch() {
          this.searchParameter = "",
              this.fetchproducts();
      }
  },
  mounted() {
      var token = localStorage.getItem("token");
      this.token = {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      };
      this.fetchCurrency();
      this.fetchproducts();
      this.fetchRecommendedProducts();
  },
  props: ["loading"],
};
</script>
