var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("form", [
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Warehouses"),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  staticClass:
                                    "js-states form-control bg-transparent",
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.sale.warehouse
                                          ? _vm.sale.warehouse.warehouse_name
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Customer"),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  staticClass:
                                    "js-states form-control bg-transparent",
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.sale.customer
                                          ? _vm.sale.customer
                                              .customer_first_name +
                                              " " +
                                              _vm.sale.customer
                                                .customer_last_name
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Sale Date"),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c("input", {
                                staticClass: "form-control bg-white",
                                attrs: { type: "date" },
                                domProps: { value: _vm.sale.sale_date },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c(
                            "table",
                            { staticClass: "table table-striped text-body" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "border-0 header-heading",
                                      attrs: { scope: "col" },
                                    },
                                    [_vm._v("Name")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "border-0 header-heading",
                                      attrs: { scope: "col" },
                                    },
                                    [_vm._v("Quantity")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "border-0 header-heading",
                                      attrs: { scope: "col" },
                                    },
                                    [
                                      _vm._v(
                                        "Price " +
                                          _vm._s(_vm.getCurrencyTitle())
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "border-0 header-heading",
                                      attrs: { scope: "col" },
                                    },
                                    [
                                      _vm._v(
                                        "Amount " +
                                          _vm._s(_vm.getCurrencyTitle())
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.selectedProducts,
                                  function (selectedProduct, index) {
                                    return _c("tr", {}, [
                                      _c("td", [
                                        _vm._v(
                                          " " + _vm._s(selectedProduct.title)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c("input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: "Enter Quantity",
                                          },
                                          domProps: {
                                            value: selectedProduct.qty,
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c("input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: "Enter Price",
                                          },
                                          domProps: {
                                            value: selectedProduct.price,
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c("input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            readonly: "",
                                            placeholder: "Enter Price",
                                          },
                                          domProps: {
                                            value:
                                              selectedProduct.price &&
                                              selectedProduct.qty &&
                                              !isNaN(selectedProduct.price) &&
                                              !isNaN(selectedProduct.qty)
                                                ? selectedProduct.price *
                                                  selectedProduct.qty
                                                : 0,
                                          },
                                        }),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", [_vm._v("Note")]),
                          _vm._v(" "),
                          _c("fieldset", { staticClass: "form-group mb-3" }, [
                            _c(
                              "textarea",
                              {
                                staticClass: "form-control",
                                staticStyle: { height: "130px" },
                                attrs: {
                                  id: "label-textarea",
                                  rows: "6",
                                  name: "notes",
                                  placeholder: "Please add some note",
                                  spellcheck: "false",
                                },
                              },
                              [_vm._v(_vm._s(_vm.sale.desc))]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row justify-content-end" }, [
                        _c("div", { staticClass: "col-12 col-md-3" }, [
                          _c("div", [
                            _c(
                              "table",
                              { staticClass: "table right-table mb-0" },
                              [
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark",
                                          },
                                          [
                                            _vm._v(
                                              "Subtotal " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base",
                                        },
                                        [_vm._v(_vm._s(_vm.sale.payable))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark",
                                          },
                                          [
                                            _vm._v(
                                              "Due Amount " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base",
                                        },
                                        [_vm._v(_vm._s(_vm.sale.due_amount))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark",
                                          },
                                          [
                                            _vm._v(
                                              "Discount " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base",
                                        },
                                        [
                                          _c("input", {
                                            staticClass:
                                              "form-control bg-white",
                                            attrs: { type: "text", value: "0" },
                                            domProps: {
                                              value: _vm.sale.discount,
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark",
                                          },
                                          [
                                            _vm._v(
                                              "Amount Paid " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base",
                                        },
                                        [
                                          _c("input", {
                                            staticClass:
                                              "form-control bg-white",
                                            attrs: { type: "text", value: "0" },
                                            domProps: {
                                              value: _vm.sale.amount_paid,
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _vm._m(1),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base",
                                        },
                                        [
                                          _c("input", {
                                            staticClass:
                                              "form-control bg-white",
                                            attrs: { type: "text", value: "0" },
                                            domProps: {
                                              value: _vm.sale.tax_id
                                                ? _vm.sale.tax_id.tax_title
                                                : "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _vm._m(2),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base",
                                        },
                                        [
                                          _c("input", {
                                            staticClass:
                                              "form-control bg-white",
                                            attrs: { type: "text", value: "0" },
                                            domProps: {
                                              value: _vm.sale.tax_amount,
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "\n                                            Sale Detail\n                                        "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "border-0" }, [
      _c("h5", { staticClass: "font-size-h5 mb-0 font-size-bold text-dark" }, [
        _vm._v("Tax"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "border-0" }, [
      _c("h5", { staticClass: "font-size-h5 mb-0 font-size-bold text-dark" }, [
        _vm._v("Tax Amount"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }