<template>
<div class="form-group row">
 

	
        <div class="col-md-12 col-12">
          <h2 class="text-body">Please select the offer expiration time for all products on timer</h2>
          <fieldset class="form-group mb-3">
            <input type="datetime-local" class="form-control bg-transparent text-dark" v-model="timer" ref="timer"/>
            <small class="form-text text-danger" v-if="errors.has('timer')" v-text="errors.get('timer')"></small>
          </fieldset>
        </div>

	

    <div class="col-md-12">
		<br />
        <button @click="updateSetting()" type="submit" class="btn btn-primary">Submit</button>
    </div>

</div>
</template>

<script>
import ErrorHandling from './../../../ErrorHandling'
export default {
    data() {
        return {
            timer: "",
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        };
    },

    methods: {
        fetchSetting() {
            this.$emit('updateLoadingState', true);
            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            axios.get('/api/admin/timer', config)
                .then(res => {
                    
                    console.log(res.data.data)
                    this.timer = res.data.data.timer;
                })
                .finally(() => (this.$emit('updateLoadingState', false)));
        },

        updateSetting() {
            this.$emit('updateLoadingState', true);
          
           var timer= this.timer
            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            axios.post('/api/admin/timer/timer', {
                    _method: 'PUT',
                    timer
                }, config)
                .then(res => {
                    if (res.data.status == "Success") {
						this.$toaster.success('Timer has been updated successfully')
                    }
                    else if(res.data.status == 'Error'){
                        this.$toaster.error(res.data.message)
                    }
                    
                })
                .catch(err => {
                    if(err.response.data.status == 'Error'){
                        this.$toaster.error(err.response.data.message)
                    }
                })
                .finally(() => (this.$emit('updateLoadingState', false)));

        }
    },
    mounted() {
        this.fetchSetting();
    }
};
</script>
