import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=83454962"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=83454962&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/lampp/htdocs/projects/Mattressforless/mattressforlesssTest/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('83454962')) {
      api.createRecord('83454962', component.options)
    } else {
      api.reload('83454962', component.options)
    }
    module.hot.accept("./Header.vue?vue&type=template&id=83454962", function () {
      api.rerender('83454962', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/partials/Header.vue"
export default component.exports